import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedService } from '../../../../../services/shared.service';
import { UserService } from '../../../../../services/user.service';
import { BluApiService } from '../../../../../services/blu-api.service';
import { OnboardingService } from '@app/services/onboarding.service';
import { OnboardingStepKey } from '@shared/interfaces';

@Component({
  selector: 'blu-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class AddUserComponent implements OnInit {
  height?: string;
  width?: string;
  name?: string;
  id?: string;
  tags: string[] = [];
  number = {
    countryCode: '55',
    areaCode: '',
    number: '',
  };

  constructor(
    public sharedService: SharedService,
    public userService: UserService,
    public route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    private bluApi: BluApiService,
    private onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.id = id;
    this.getUser(this.id);
  }

  private async getUser(id: string): Promise<void> {
    const user = await this.userService.getUserById(id);
    if (!user) return;
    this.name = user.name;
    this.id = user.uid;
    this.tags = user.tags || [];
    this.number = {
      countryCode: user.numbers[0].substring(0, 2),
      areaCode: user.numbers[0].substring(2, 4),
      number: user.numbers[0].substring(4),
    };
  }

  setNumber(
    key: 'countryCode' | 'areaCode' | 'number',
    event: any,
    maxlength: number,
  ): void {
    const number = event.target.value
      .replace(/\D/g, '')
      .substring(0, maxlength);
    this.number[key] = number ? number : '';
    event.target.value = this.number[key];
  }

  setName(event: any): void {
    this.name = event.target.value;
  }

  async saveUser(): Promise<void> {
    // model data
    let docData;
    if (this.number.countryCode === '55') {
      const jointNumber = `${this.number.countryCode}${this.number.areaCode}${this.number.number}`;
      const jointNumberExtra9 = `${this.number.countryCode}${this.number.areaCode}9${this.number.number}`;
      docData = {
        name: this.name,
        numbers: [jointNumber, jointNumberExtra9],
        tags: this.tags,
      };
    } else {
      const jointNumber = `${this.number.countryCode}${this.number.areaCode}${this.number.number}`;
      docData = {
        name: this.name,
        numbers: [jointNumber],
        tags: this.tags,
      };
    }

    // if editing existing user, update data
    if (this.id) {
      await this.userService.updateUser(this.id, docData);
    } else {
      await this.userService.createUser(docData);
      this.onboardingService.completeStep(OnboardingStepKey.createdUser);
    }
    this.sharedService.closeModal(this.route);
  }

  deleteUser() {
    if (!this.id) return;
    this.userService.deleteUser(this.id);
    this.sharedService.closeModal(this.route);
  }

  toggleTag(tag: string): void {
    const tagIndex = this.tags?.indexOf(tag);
    if (tagIndex < 0) this.tags.push(tag);
    else this.tags.splice(tagIndex, 1);
  }

  async exportContactsToCsv(): Promise<void> {
    if (!this.id) return;

    // get all contacts for user
    const contacts = await this.bluApi.getContactsByUserId(this.id, true);

    // model array of numbers & name
    const rows: string[][] = [['Numero', 'Nome']];
    contacts.forEach((doc) => {
      rows.push([doc.number || '', doc.name || '']);
    });

    // export
    const csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `Contatos_${this.name}_${new Date().toLocaleDateString()}.csv`,
    );
    document.body.appendChild(link);

    link.click();
  }
}
