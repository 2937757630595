<!-- splash -->
<div class="bg-accent-950 absolute top-0 left-0 w-full h-80 -z-10"></div>

<!-- header -->
<blu-header theme="dark"></blu-header>

<!-- KPIs -->
<div class="kpis">
  <blu-kpi
    [value]="messages.currentPeriod.sum"
    [label]="'Mensagens'"
    [title]="'Últimos 30 dias'"
    [trend]="messages.currentPeriod.sum - messages.previousPeriod.sum"
    (click)="analyticsService.logEvent('user_messages_kpi_click')"
  ></blu-kpi>
  <blu-kpi
    [value]="campaigns.currentPeriod.sum"
    [label]="'Disparos'"
    [title]="'Últimos 30 dias'"
    [trend]="campaigns.currentPeriod.sum - campaigns.previousPeriod.sum"
    (click)="analyticsService.logEvent('user_campaigns_kpi_click')"
  ></blu-kpi>
</div>

<!-- content -->
<router-outlet></router-outlet>

<!-- modal -->
<router-outlet name="modal"></router-outlet>
