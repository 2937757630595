import { Injectable, inject } from '@angular/core';
import { where } from '@angular/fire/firestore';
import {
  FirestoreCollections,
  IChatCategory,
} from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private firestore = inject(SharedFirestoreService);

  categories: IChatCategory[] = [];

  async getCategories(orgId: string) {
    if (this.categories.length) return;

    const categoriesSnap = await this.firestore.getDocs<IChatCategory>(
      FirestoreCollections.Category,
      where('organization', '==', orgId),
    );
    this.categories = categoriesSnap.docs.map((categoryDocSnap) =>
      categoryDocSnap.data(),
    );
  }

  async getCategory(categoryId: string): Promise<IChatCategory | undefined> {
    if (this.categories.length) {
      return this.categories.find((category) => category.uid === categoryId);
    }
    return this.firestore.getDoc<IChatCategory>(
      FirestoreCollections.Category,
      categoryId,
    );
  }

  async updateCategory(
    categoryId: string,
    category: Partial<IChatCategory>,
  ): Promise<void> {
    await this.firestore.updateDoc(
      FirestoreCollections.Category,
      categoryId,
      category,
    );
    const index = this.categories.findIndex((c) => c.uid === categoryId);
    if (index > -1) this.categories[index] = category as IChatCategory;
  }

  async createCategory(category: IChatCategory): Promise<void> {
    const chRef = await this.firestore.addDoc(
      FirestoreCollections.Category,
      category,
    );
    this.categories.push({ ...category, uid: chRef.id } as IChatCategory);
  }

  async deleteCategory(categoryId: string): Promise<void> {
    await this.firestore.deleteDoc(FirestoreCollections.Category, categoryId);
    const index = this.categories.findIndex((c) => c.uid === categoryId);
    if (index > -1) this.categories.splice(index, 1);
  }
}
