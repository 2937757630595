import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelsService } from '@app/services/channels.service';
import { FlowListService } from '@app/services/flow-list.service';
import { SharedService } from '@app/services/shared.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { IChannel } from 'libs/shared/src/lib/interfaces';

@Component({
  selector: 'blu-select-flow',
  templateUrl: './select-flow.component.html',
  styleUrls: ['./select-flow.component.scss'],
})
export class SelectFlowComponent implements OnInit {
  channel?: IChannel;

  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public flowListService: FlowListService,
    private channelService: ChannelsService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.getChannel(id);
    if (!this.flowListService.flows.length) this.flowListService.getFlows();
  }

  private async getChannel(id: string): Promise<void> {
    const channel = await this.channelService.getChannel(id);
    if (!channel) return;
    this.channel = channel as IChannel;
  }

  selectFlow(flowId: string) {
    if (!this.channel) return;
    if (this.channel.activeFlow === flowId) this.channel.activeFlow = null;
    else this.channel.activeFlow = flowId;
  }

  async save(): Promise<void> {
    if (!this.channel?.uid) return;
    await this.channelService.updateChannel(this.channel.uid, {
      activeFlow: this.channel.activeFlow || null,
    });
    this.sharedService.closeModal(this.route);
  }
}
