<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? 'auto' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <header class="header">
    <h2>Criar opção de resposta</h2>
    <kor-icon
      button
      icon="close"
      (click)="sharedService.closeModal(route)"
    ></kor-icon>
  </header>
  <kor-input
    label="Opção de resposta"
    [value]="pollOption"
    (input)="
      setPollOption($event);
      analyticsService.logEvent('user_add-poll-option_body_input')
    "
  ></kor-input>
  <!-- footer -->
  <div class="footer">
    <kor-button
      label="Cancelar"
      color="secondary"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('user_add-poll-option_close_click')
      "
    ></kor-button>
    <kor-button
      [disabled]="!pollOption"
      label="Confirmar"
      (click)="
        createPollOption();
        sharedService.closeModal(route);
        analyticsService.logEvent('user_add-poll-option_confirm_click')
      "
    ></kor-button>
  </div>
</div>
