import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';
import { environment } from 'libs/shared/src/lib/environments/environment';

Sentry.init({
  dsn: 'https://9ea8f1b2080c47a29e38759dc915f735@o548650.ingest.sentry.io/6385929',
  environment: environment.production ? 'production' : 'development',
  tracesSampleRate: 0.1,
  integrations: [Sentry.browserTracingIntegration()],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
