import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataItem } from '@swimlane/ngx-charts';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { ChartService } from '@app/services/chart.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'blu-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class SendStatsComponent implements OnInit {
  sharedService = inject(SharedService);
  chartService = inject(ChartService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  analyticsService = inject(AnalyticsService);
  userService = inject(UserService);

  loading = true;
  selectedChart: 'messages' | 'campaigns' = 'messages';
  chartData?: DataItem[];

  ngOnInit(): void {
    this.chartService.dataLoaded.subscribe((type) => {
      if (type !== this.selectedChart) return;
      this.loading = false;
      this.getChartData(type);
    });
  }

  selectChart(name: 'messages' | 'campaigns') {
    this.selectedChart = name;
    this.getChartData(name);
  }

  getChartData(name: 'messages' | 'campaigns'): void {
    switch (name) {
      case 'messages':
        this.chartData = this.chartService.messages.currentPeriod.series;
        return;
      case 'campaigns':
        this.chartData = this.chartService.campaigns.currentPeriod.series;
        return;
      default:
        return;
    }
  }

  formatDateTick(dateString: any): string {
    const dateParts = dateString.split('/');
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return `${dateObject.getDate()} ${dateObject.toLocaleString('pt-br', {
      month: 'short',
    })}`;
  }
}
