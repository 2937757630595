import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { arrayUnion } from '@firebase/firestore';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'blu-add-user-tag',
  templateUrl: './add-user-tag.component.html',
  styleUrls: ['./add-user-tag.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class AddUserTagComponent {
  tagName?: string;

  constructor(
    public sharedService: SharedService,
    public route: ActivatedRoute,
    private userService: UserService,
    private firestore: SharedFirestoreService,
    public analyticsService: AnalyticsService,
  ) {}

  setTagName(tar: EventTarget | null): void {
    const el = tar as HTMLInputElement;
    this.tagName = el.value;
  }

  async createTag(): Promise<void> {
    const org = this.userService.organization();
    if (!this.tagName || !org) return;
    this.userService.organization.set({
      ...org,
      userTags: [...new Set([...(org.userTags || []), this.tagName])],
    });

    await this.firestore.setDoc(
      'organization',
      org.uid,
      { userTags: arrayUnion(this.tagName) },
      { merge: true },
    );
    this.sharedService.closeModal(this.route);
  }
}
