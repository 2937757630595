import { Injectable } from '@angular/core';
import {
  Auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  onAuthStateChanged,
} from '@angular/fire/auth';
import {} from '@firebase/auth';
import { firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { AdminLoginError, AuthUser } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '@shared/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedAuthService {
  loginError = new ReplaySubject<string>(1);
  loginSuccess = new ReplaySubject<string>(1);
  authChange$ = new Subject<any>();

  constructor(
    private auth: Auth,
    private http: HttpClient,
  ) {}

  initAuth(): void {
    onAuthStateChanged(this.auth, (user) => {
      this.authChange$.next(user);
    });
  }

  async register(
    email: string,
    password: string,
    displayName: string,
    phoneNumber?: string,
  ): Promise<void | AuthUser> {
    const body = {
      email,
      password,
      displayName,
      phoneNumber: phoneNumber && `+55${phoneNumber}`,
    };
    try {
      const userAuth = await firstValueFrom(
        this.http.post<AuthUser>(
          environment.cloudFunctionsApiUrl + '/auth',
          body,
        ),
      );
      return userAuth;
    } catch (error) {
      let errorCode =
        (error as any).error?.error ||
        (error as any).code ||
        (error as any).error;
      const code = (error as any).error?.code;
      if (code && code === 'auth/email-already-exists') {
        errorCode = `Email já em uso. Se este é o seu email, por favor <a class="link" href="https://blu.direct/sign-in/admin?email=${email}">faça o login</a>.`;
      }
      this.displayErrorMessage(errorCode);
    }
  }

  async signIn(
    email?: string,
    password?: string,
  ): Promise<UserCredential | undefined> {
    if (!email || !password) return;
    try {
      const credentials = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      );
      return credentials;
    } catch (error) {
      const errorCode = (error as any).code as keyof typeof AdminLoginError;
      const errorMessage = AdminLoginError[errorCode] || errorCode;
      this.loginError.next(errorMessage);
    }
    return;
  }

  async resetPassword(email: string | undefined): Promise<void> {
    if (!email) return;
    try {
      await sendPasswordResetEmail(this.auth, email);
      this.loginError.next('');
      this.loginSuccess.next(
        'Email enviado com as instruções para resetar a senha.',
      );
    } catch (error) {
      this.displayErrorMessage((error as any).code);
    }
  }

  displayErrorMessage(errorCode: string) {
    const errorMessage =
      AdminLoginError[errorCode as keyof typeof AdminLoginError] || errorCode;
    this.loginError.next(errorMessage);
  }

  async getToken() {
    const user: User | null = await new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
    if (!user) throw new Error('No user logged in');
    return user.getIdToken();
  }

  signOut(): void {
    signOut(this.auth);
  }
}
