import { Injectable } from '@angular/core';
import {
  DocumentData,
  DocumentReference,
  Firestore,
  onSnapshot,
} from '@angular/fire/firestore';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  query,
  setDoc,
  updateDoc,
} from '@firebase/firestore';
import { IFlow, INode } from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  draggedOverNodeId?: string;
  draggedResponseOption?: { index: number; parentNodeId: string };
  sourceNodeId?: string;
  flow?: IFlow;
  nodes: INode[] = [];
  hasNodesChanged: Subject<void> = new Subject<void>();

  constructor(
    private afs: Firestore,
    private firestoreService: SharedFirestoreService,
  ) {}

  async getFlow(flowDocId: string): Promise<void> {
    const flowDocRef = doc(this.afs, `flow/${flowDocId}`);
    const flowDocSnap = await getDoc(flowDocRef);
    this.flow = flowDocSnap.data() as IFlow;
  }

  async getNodes(flowDocId: string): Promise<void> {
    const nodeColRef = collection(this.afs, `flow/${flowDocId}/node`);
    const nodeColQuery = query(nodeColRef);
    onSnapshot(nodeColQuery, (nodeColQuerySnap) => {
      for (const change of nodeColQuerySnap.docChanges()) {
        const node = change.doc.data() as INode;
        switch (change.type) {
          case 'added':
            node.uid = change.doc.id;
            this.nodes.push(node);
            break;
          case 'modified': {
            const nodeIndex = this.nodes.findIndex(
              (n) => n.uid === change.doc.id,
            );
            if (
              JSON.stringify(this.nodes[nodeIndex]) !== JSON.stringify(node)
            ) {
              this.nodes[nodeIndex] = Object.assign(
                this.nodes[nodeIndex] || {},
                node,
              );
            }
            break;
          }
          case 'removed':
            this.nodes = this.nodes.filter((n) => n.uid !== change.doc.id);
            break;
        }
        this.hasNodesChanged.next();
      }
    });
  }

  async setNode(
    flowDocId?: string,
    nodeDocId?: string,
    nodeDocData?: INode,
  ): Promise<void> {
    if (!flowDocId || !nodeDocId || !nodeDocData) return;
    const nodeDocRef = doc(this.afs, `flow/${flowDocId}/node/${nodeDocId}`);
    await setDoc(nodeDocRef, nodeDocData);
  }

  async addNode(
    flowDocId?: string,
    nodeDocData?: Partial<INode>,
  ): Promise<DocumentReference<DocumentData> | undefined> {
    if (!flowDocId || !nodeDocData) return;
    return this.firestoreService.addDoc(`flow/${flowDocId}/node`, nodeDocData);
  }

  async deleteNode(flowDocId?: string, nodeDocId?: string): Promise<void> {
    if (!flowDocId || !nodeDocId) return;
    const promises = [
      this.firestoreService.deleteDoc(`flow/${flowDocId}/node`, nodeDocId),
    ];
    for (const node of this.nodes) {
      for (const option of node.responseOptions) {
        if (option.actionNode === nodeDocId) {
          option.actionNode = null;
          promises.push(this.setNode(flowDocId, node.uid, node));
        }
      }
    }
    await Promise.all(promises);
  }

  async addResponseOption(
    flowDocId: string,
    nodeDocId?: string,
    index?: number,
  ): Promise<void> {
    const nodeDocRef = doc(this.afs, `flow/${flowDocId}/node/${nodeDocId}`);
    await updateDoc(nodeDocRef, {
      responseOptions: arrayUnion({
        label: 'Nova resposta',
        index: index || 0,
        actionNode: null,
      }),
    });
  }

  async deleteResponseOption(
    flowDocId: string,
    node: INode,
    optionIndex: number,
  ): Promise<void> {
    const nodeDocRef = doc(this.afs, `flow/${flowDocId}/node/${node.uid}`);
    await updateDoc(nodeDocRef, {
      responseOptions: node.responseOptions
        .filter((o) => o.index !== optionIndex)
        .map((o, i) => ({ ...o, index: i })),
    });
  }
}
