import { Component, OnInit } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import {
  FirestoreCollections,
  ITemplate,
} from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { UserService } from '@app/services/user.service';
import { SendService } from '../../../../../services/send.service';
import { SharedService } from '../../../../../services/shared.service';

@Component({
  selector: 'blu-choose-template',
  templateUrl: './choose-template.component.html',
  styleUrls: ['./choose-template.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class ChooseTemplateComponent implements OnInit {
  templates: ITemplate[] = [];
  selectedTemplate: any;

  constructor(
    public sharedService: SharedService,
    public route: ActivatedRoute,
    private firestore: SharedFirestoreService,
    private sendService: SendService,
    private userService: UserService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.getTemplates();
  }

  private async getTemplates(): Promise<void> {
    const user = this.userService.user();
    if (!user) {
      throw new Error('User not found while fetching templates');
    }
    if (!user.organization) {
      throw new Error('Organization not found while fetching templates');
    }
    const querySnap = await this.firestore.getDocs<ITemplate>(
      FirestoreCollections.Template,
      where('organization', '==', user.organization),
    );
    querySnap.docs.forEach((docSnap) => this.templates.push(docSnap.data()));
  }

  chooseTemplate(): void {
    if (this.selectedTemplate) {
      this.sendService.content.media = this.selectedTemplate.media || [];
      this.sendService.content.text = this.selectedTemplate.text;
      this.sendService.content.poll.options =
        this.selectedTemplate.buttons || [];
    }
    this.sharedService.closeModal(this.route);
  }
}
