import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  IRandomVariable,
  IRecipient,
  MessageContent,
} from 'libs/shared/src/lib/interfaces';
import { UserService } from './user.service';
import { BluApiService } from './blu-api.service';
import {
  ToastMessage,
  ToastService,
} from 'libs/shared/src/lib/services/toast.service';

export const randomVariables: IRandomVariable[] = [
  {
    name: 'Saudação',
    values: ['Olá', 'Oi', 'Tudo bem?', 'Como vai?', 'Oi!', 'Olá!'],
  },
  {
    name: 'Despedida',
    values: ['Até mais!', 'Até a próxima!', 'Tchau!', 'Tenha um ótimo dia!'],
  },
];

@Injectable({
  providedIn: 'root',
})
export class SendService {
  messageLimitNumber = 10000;
  csvHeaders?: string[];
  content: MessageContent = {
    text: undefined,
    media: [],
    viewOnce: false,
    poll: {
      name: null,
      options: [],
    },
  };
  recipients: IRecipient[] = [];
  campaign = {
    uid: <string | undefined>undefined,
    messagesSent: 0,
  };
  recipientSource: 'csv' | 'whatsapp' | 'template' = 'whatsapp';
  duplicatesTimeout?: NodeJS.Timeout;
  randomVariables = randomVariables;

  constructor(
    private userService: UserService,
    private bluApi: BluApiService,
    private toastService: ToastService,
  ) {
    this.bluApi.progress$.subscribe((progress) => {
      this.campaign.messagesSent = +progress;
    });
  }

  getContactIndex(contactId: string) {
    return this.recipients.findIndex(
      (recipient) => recipient.chatId === contactId,
    );
  }

  get progress(): number {
    return (this.campaign.messagesSent / this.recipients.length) * 100;
  }

  async addAllUserContactsToRecipients() {
    const user = this.userService.user();
    if (!user) throw new Error('User not found on template');
    const contacts = await this.bluApi.getContactsByUserId(user.uid, true);
    contacts.forEach((contact) => {
      this.addRecipient({ chatId: contact.whatsappId });
    });
  }

  removeButton(button: string): void {
    const buttonIndex = this.content.poll.options.indexOf(button);
    if (buttonIndex < 0) return;
    this.content.poll.options.splice(buttonIndex, 1);
  }

  setRecipients(recipients: IRecipient[]): void {
    this.recipients = recipients;
    this.triggerDuplicatesTimer();
  }

  addRecipient(recipient: IRecipient): void {
    this.recipients.push(recipient);
    this.triggerDuplicatesTimer();
  }

  triggerDuplicatesTimer(): void {
    if (this.duplicatesTimeout) clearTimeout(this.duplicatesTimeout);
    this.duplicatesTimeout = setTimeout(() => this.alertDuplicates(), 1000);
  }

  alertDuplicates(): void {
    if (!this.recipients.length) return;
    const hasDuplicate = this.recipients.some(
      (val, i) =>
        this.recipients.findIndex((obj) => obj.chatId === val.chatId) !== i,
    );
    if (!hasDuplicate) return;
    this.toastService.createToast({
      title: 'Contatos Duplicados',
      message: ToastMessage.DuplicateContacts,
      icon: 'warning',
      iconColor: 'rgb(var(--functional-yellow))',
    });
  }

  async setVariableValues(): Promise<void> {
    const textVariables = this.content.text?.match(/{{([^}]+)\}}/g);
    if (!textVariables) return;

    const varObjectArr: IRandomVariable[] = [];
    for (const variable of textVariables) {
      const variableName = variable.replace(/{{|}}/g, '');
      const varObject = randomVariables.find(
        (randomVar) => randomVar.name === variableName,
      );
      if (!varObject || varObjectArr.indexOf(varObject) > -1) continue;
      varObjectArr.push(varObject);
    }

    for (const recipient of this.recipients) {
      for (const varObject of varObjectArr) {
        const randomIndex = Math.floor(Math.random() * varObject.values.length);
        const randomValue = varObject.values[randomIndex];
        if (!recipient.variables) recipient.variables = {};
        recipient.variables[varObject.name] = randomValue;
      }
    }
  }
}
