import { inject, Injectable } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';
import {
  OnboardingStepKey,
  IOrganization,
  FirestoreCollections,
  IOnboarding,
} from '@shared/interfaces';
import { SharedFirestoreService } from '@shared/services/firestore.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  firestore = inject(SharedFirestoreService);
  userService = inject(UserService);

  isStepCompleted(org: IOrganization, stepName: OnboardingStepKey): boolean {
    return !!org.onboarding?.[stepName]?.completedAt;
  }

  completeStep(stepName: OnboardingStepKey) {
    const org = this.userService.organization();
    if (!org) throw new Error('Organization not found.');
    if (this.isStepCompleted(org, stepName)) return;
    this.firestore.setDoc<IOrganization>(
      FirestoreCollections.Organization,
      org.uid,
      {
        onboarding: {
          [stepName]: { completedAt: serverTimestamp() },
        } as IOnboarding,
      },
      { merge: true },
    );
  }

  dismissStep(stepName: OnboardingStepKey) {
    const org = this.userService.organization();
    if (!org) throw new Error('Organization not found.');
    this.firestore.setDoc<IOrganization>(
      FirestoreCollections.Organization,
      org.uid,
      {
        onboarding: {
          [stepName]: { dismissedAt: serverTimestamp() },
        } as IOnboarding,
      },
      { merge: true },
    );
  }
}
