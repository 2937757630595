import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModalComponent } from './components/modal/modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SharedModalComponent],
  exports: [SharedModalComponent],
})
export class SharedModule {}
