<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? 'auto' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <!-- header -->
  <header class="header">
    <h2>Criar categoria</h2>
    <kor-icon
      button
      icon="close"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('admin_add-user-tag-modal_close_button_click')
      "
    ></kor-icon>
  </header>
  <!-- body -->
  <kor-input
    label="Nome"
    [value]="tagName"
    (input)="setTagName($event.target)"
  ></kor-input>

  <footer class="footer">
    <kor-button
      label="Criar"
      (click)="
        createTag();
        analyticsService.logEvent(
          'admin_add-user-tag-modal_confirm_button_click'
        )
      "
    ></kor-button>
  </footer>
</div>
