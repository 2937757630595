import { Injectable } from '@angular/core';
import { arrayRemove, orderBy, where } from '@angular/fire/firestore';
import {
  FirestoreCollections,
  IOperator,
  OnboardingStepKey,
} from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { OnboardingService } from './onboarding.service';

@Injectable({
  providedIn: 'root',
})
export class OperatorsService {
  operators: IOperator[] = [];

  constructor(
    private firestore: SharedFirestoreService,
    private onboardingService: OnboardingService,
  ) {}

  async getOperators(orgId: string) {
    if (this.operators.length) return;

    const querySnap = await this.firestore.getDocs(
      FirestoreCollections.Operator,
      where('deletedAt', '==', null),
      where('organization', '==', orgId),
      orderBy('createdAt', 'desc'),
    );
    this.operators = querySnap.docs.map((doc) => doc.data() as IOperator);
  }

  async getOperator(operatorId: string): Promise<IOperator | undefined> {
    if (this.operators.length) {
      return this.operators.find((operator) => operator.uid === operatorId);
    }
    return this.firestore.getDoc<IOperator>(
      FirestoreCollections.Operator,
      operatorId,
    );
  }

  async deleteOperator(operatorId: string, orgId: string) {
    // FIXME: delete user from auth?
    const promises = [
      this.firestore.updateDoc(FirestoreCollections.Operator, operatorId, {
        deletedAt: new Date(),
      }),
    ];
    const index = this.operators.findIndex(
      (operator) => operator.uid === operatorId,
    );
    if (index > -1) this.operators.splice(index, 1);

    const channelsSnap = await this.firestore.getDocs(
      FirestoreCollections.Channel,
      where('organization', '==', orgId),
      where('operators', 'array-contains', operatorId),
    );
    for (const channelDocSnap of channelsSnap.docs) {
      promises.push(
        this.firestore.updateDoc(
          FirestoreCollections.Channel,
          channelDocSnap.id,
          { operators: arrayRemove(operatorId) },
        ),
      );
    }
    await Promise.all(promises);
  }

  async setOperator(operatorId: string, operator: IOperator): Promise<void> {
    this.firestore.setDoc(
      FirestoreCollections.Operator,
      operatorId,
      operator,
      {},
    );
    this.onboardingService.completeStep(OnboardingStepKey.createdOperator);
    const index = this.operators.findIndex(
      (operator) => operator.uid === operatorId,
    );
    if (index > -1) this.operators[index] = operator;
    else this.operators.push(operator);
  }
}
