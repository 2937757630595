<div class="card" [ngClass]="{ active: active, button: button }">
  <h2>
    {{ label }}<br />
    <p>{{ title }}</p>
  </h2>
  <h1>
    {{ value | number }}
    @if (trend) {
      <div
        class="trend"
        [ngClass]="{
          negative: trend < 0,
          positive: trend > 0
        }"
      >
        <kor-icon
          size="s"
          [icon]="trend > 0 ? 'trending_up' : 'trending_down'"
        ></kor-icon>
        {{ trend | number }}
      </div>
    }
  </h1>
</div>
