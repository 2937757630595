import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class SharedModalComponent {
  @Input() width: string = '480px';
  @Input() height: string = 'auto';
  @Input() mobile = false;
  constructor() {}
}
