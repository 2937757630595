import { Component, effect } from '@angular/core';
import { collection, onSnapshot, query, where } from '@angular/fire/firestore';
import {
  FirestoreCollections,
  ITemplate,
} from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from '@shared/services/analytics.service';
import { BluApiService } from '@app/services/blu-api.service';
import { SharedFirestoreService } from '@shared/services/firestore.service';
import { SharedService } from '@app/services/shared.service';
import { TemplateService } from '@app/services/template.service';
import { UserService } from '@app/services/user.service';

interface IHydratedTemplate extends ITemplate {
  guestsCount: number;
}

@Component({
  selector: 'blu-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
})
export class TemplatesListComponent {
  templates: IHydratedTemplate[] = [];
  guestRecipientsCount?: number;
  loadingGuestRecipientsCsv = false;
  loadingGuestRecipientsCount = false;

  constructor(
    private firestore: SharedFirestoreService,
    public userService: UserService,
    public analyticsService: AnalyticsService,
    public sharedService: SharedService,
    private bluApi: BluApiService,
    private templateService: TemplateService,
  ) {
    effect(() => {
      const org = this.userService.organization();
      if (!org) return;
      this.getData(org.uid);
    });
  }

  private async getData(orgId: string): Promise<void> {
    this.getTemplates(orgId);
    this.loadingGuestRecipientsCount = true;
    let guestRecipientsCount: any;
    try {
      guestRecipientsCount = await this.bluApi.getGuestRecipients(
        orgId,
        this.userService
          .organization()
          ?.lastGuestRecipientsExportDate?.toDate(),
        true,
      );
      this.guestRecipientsCount = guestRecipientsCount.count;
    } catch (e) {
      guestRecipientsCount = 0;
    }
    this.loadingGuestRecipientsCount = false;
  }

  public async getGuestRecipientsCsv(): Promise<void> {
    const org = this.userService.organization();
    if (!org) return;
    this.loadingGuestRecipientsCsv = true;
    const guestRecipientsCsv = await this.bluApi.getGuestRecipients(
      org.uid,
      org.lastGuestRecipientsExportDate?.toDate(),
      false,
    );
    this.downloadLeadsCsv(guestRecipientsCsv as Blob);
    this.loadingGuestRecipientsCsv = false;
    this.firestore.setDoc(
      'organization',
      org.uid,
      { lastGuestRecipientsExportDate: new Date() },
      { merge: true },
    );
  }

  downloadLeadsCsv(file: Blob): void {
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    const date = new Date()
      .toISOString()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('-');
    link.download = `Blü Direct - Leads ${date}.csv`;
    link.click();
    URL.revokeObjectURL(fileURL);
  }

  private async getTemplates(orgId: string): Promise<void> {
    const templatesQuery = query(
      collection(this.firestore.afs, FirestoreCollections.Template),
      where('organization', '==', orgId),
    );

    onSnapshot(templatesQuery, async (templatesQuerySnap) => {
      for (const change of templatesQuerySnap.docChanges()) {
        const templateData = change.doc.data() as ITemplate;
        const templateId = change.doc.id;

        switch (change.type) {
          case 'added': {
            const guestsCountAdded =
              await this.templateService.getLeadsForTemplate(templateId);
            const newTemplate: IHydratedTemplate = {
              ...templateData,
              uid: templateId,
              guestsCount: guestsCountAdded,
            };
            this.templates.push(newTemplate);
            break;
          }
          case 'modified': {
            const templateIndex = this.templates.findIndex(
              (t) => t.uid === templateId,
            );
            if (templateIndex !== -1) {
              const guestsCountModified =
                await this.templateService.getLeadsForTemplate(templateId);
              const updatedTemplate: IHydratedTemplate = {
                ...templateData,
                uid: templateId,
                guestsCount: guestsCountModified,
              };
              if (
                JSON.stringify(this.templates[templateIndex]) !==
                JSON.stringify(updatedTemplate)
              ) {
                this.templates[templateIndex] = Object.assign(
                  this.templates[templateIndex] || {},
                  updatedTemplate,
                );
              }
            }
            break;
          }

          case 'removed':
            this.templates = this.templates.filter((t) => t.uid !== templateId);
            break;
        }
      }

      // If you want to notify about changes, you can add a Subject here
      // this.hasTemplatesChanged.next();
    });
  }
}
