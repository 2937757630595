<a
  class="card button"
  routerLink="/sign-in/user"
  (click)="analyticsService.logEvent('sign-in_user-card_click')"
>
  <kor-icon size="xl" icon="url('assets/icons/user.png')"></kor-icon>
  Realizar disparo
</a>
<a
  class="card button"
  routerLink="/sign-in/admin"
  (click)="analyticsService.logEvent('sign-in_admin-card_click')"
>
  <kor-icon size="xl" icon="url('assets/icons/admin.png')"></kor-icon>
  Painel de administrador
</a>
<a
  class="card button"
  href="https://chat.blu.direct"
  (click)="analyticsService.logEvent('sign-in_operator-card_click')"
>
  <kor-icon size="xl" icon="url('assets/icons/chat.png')"></kor-icon>
  Atendimento
</a>
