<dialog class="modal-wrapper" open>
  <div
    class="card"
    [style.height]="mobile ? '100%' : height"
    [style.width]="mobile ? '100%' : width"
  >
    <!-- header -->
    <header class="header">
      <ng-content select="[slot='header']"></ng-content>
    </header>
    <!-- content -->
    <ng-content></ng-content>
    <!-- footer -->
    <footer class="footer">
      <ng-content select="[slot='footer']"></ng-content>
    </footer>
  </div>
</dialog>
