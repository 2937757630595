<shared-modal [mobile]="sharedService.screenSize !== 'l'">
  <!-- header -->
  <h2 slot="header">
    @if (flow.uid) {
      Modificar chatbot
    } @else {
      Criar chatbot
    }
  </h2>

  <!-- body -->
  <kor-input
    label="Nome"
    [value]="flow.label"
    (input)="setLabel($event.target)"
  ></kor-input>

  <!-- footer -->
  @if (flow.uid) {
    <kor-button
      class="mr-auto"
      slot="footer"
      color="tertiary"
      label="Deletar"
      (click)="delete(); analyticsService.logEvent('admin_delete_flow_click')"
    ></kor-button>
  }
  <kor-button
    slot="footer"
    color="secondary"
    label="Cancelar"
    (click)="
      sharedService.closeModal(route);
      analyticsService.logEvent('admin_close_flow_modal')
    "
  >
  </kor-button>
  <kor-button
    slot="footer"
    label="Confirmar"
    [disabled]="!flow.label"
    (click)="save(); analyticsService.logEvent('admin_save_flow_click')"
  >
  </kor-button>
</shared-modal>
