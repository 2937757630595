import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FlowListService } from '@app/services/flow-list.service';
import { FlowService } from '../../../../../services/flow.service';
import { INode } from 'libs/shared/src/lib/interfaces';

@Component({
  selector: 'flow-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss'],
  host: { class: 'card' },
})
export class FlowComponent implements OnInit {
  @ViewChild('flow') flow?: ElementRef;
  isAutoAligning = false;

  constructor(
    public flowService: FlowService,
    private flowListService: FlowListService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.flowService.flow = undefined;
    this.flowService.nodes = [];
    this.route.params.subscribe((params: Params) => {
      const flowId = params['id'];
      if (flowId) this.initializeFlow(flowId);
    });
    this.flowService.hasNodesChanged.subscribe(() =>
      setTimeout(() => this.drawConnectionLines(), 0),
    );
  }

  private async initializeFlow(flowId: string): Promise<void> {
    await Promise.all([
      this.flowService.getFlow(flowId),
      this.flowService.getNodes(flowId),
    ]);
    setTimeout(() => this.drawConnectionLines(), 0);
  }

  onOptionDragStart(output: {
    event: DragEvent;
    nodeId: string;
    optionId: number;
  }) {
    const { event, nodeId, optionId } = output;
    this.flowService.draggedOverNodeId = undefined;
    this.flowService.sourceNodeId = nodeId;
    this.flowService.draggedResponseOption = {
      index: optionId,
      parentNodeId: nodeId,
    };
  }

  onOptionDragEnd(event: DragEvent) {
    this.flowService.draggedOverNodeId = undefined;
    this.flowService.sourceNodeId = undefined;
    this.flowService.draggedResponseOption = undefined;
  }

  onNodeDragEnter(event: DragEvent) {
    if ((event.currentTarget as any).contains(event.relatedTarget)) return;
    if (
      this.flowService.draggedResponseOption?.parentNodeId ===
      (event.currentTarget as any).id
    ) {
      return;
    }
    this.flowService.draggedOverNodeId = (event.currentTarget as any).id;
  }

  onNodeDragLeave(event: DragEvent) {
    if ((event.currentTarget as any).contains(event.relatedTarget)) return;
    this.flowService.draggedOverNodeId = undefined;
  }

  // this is needed to allow the drop event to fire
  onNodeDragOver(event: DragEvent) {
    event.preventDefault();
    this.drawConnectionLines();
  }

  onNodeDrop(event: DragEvent) {
    if (!this.flowService.draggedResponseOption) return;
    const targetNodeId = (event.currentTarget as any).id.replace('n_', '');
    const targetNode = this.flowService.nodes.find(
      (node) => node.uid === targetNodeId,
    );
    const { index, parentNodeId } = this.flowService.draggedResponseOption;
    if (parentNodeId === targetNodeId) return;
    const sourceNode = this.flowService.nodes.find(
      (node) => node.uid === parentNodeId,
    );
    const option = sourceNode?.responseOptions.find(
      (option) => option.index === index,
    );
    if (targetNode && option) {
      option.actionNode = targetNode.uid;
      this.flowService.setNode(
        this.flowService.flow?.uid,
        sourceNode?.uid,
        sourceNode,
      );
    }
    this.flowService.draggedOverNodeId = undefined;
    this.flowService.sourceNodeId = undefined;
    this.flowService.draggedResponseOption = undefined;
  }

  onFlowDragOver(event: DragEvent) {
    event.preventDefault();
  }

  async onFlowDrop(event: DragEvent) {
    if (
      event.target !== this.flow?.nativeElement ||
      !this.flowService.draggedResponseOption
    ) {
      return;
    }
    const { index, parentNodeId } = this.flowService.draggedResponseOption;
    const gridSize = 8;
    const x = Math.floor(event.offsetX / gridSize);
    const y = Math.floor(event.offsetY / gridSize);
    const newNode = await this.flowService.addNode(this.flowService.flow?.uid, {
      coords: {
        x,
        y,
      },
      text: 'Nova opção',
      responseOptions: [
        {
          label: 'Nova resposta 1',
          index: 0,
          actionNode: null,
        },
        {
          label: 'Nova resposta 2',
          index: 1,
          actionNode: null,
        },
      ],
    });
    if (!newNode) return;
    const sourceNode = this.flowService.nodes.find(
      (node) => node.uid === parentNodeId,
    );
    const option = sourceNode?.responseOptions.find(
      (option) => option.index === index,
    );
    if (!option) return;
    option.actionNode = newNode.id;
    await this.flowService.setNode(
      this.flowService.flow?.uid,
      sourceNode?.uid,
      sourceNode,
    );
    this.drawConnectionLines();
  }

  drawConnectionLines() {
    const svgContainer = this.flow?.nativeElement;
    if (!svgContainer) return;
    const paths = document.querySelectorAll('svg > path') as any;

    for (const path of paths) {
      this.setLinePath(path);
    }
  }

  setLinePath(path: SVGPathElement): void {
    const optionId = path.getAttribute('data-option-id');
    const nodeId = path.getAttribute('data-node-id');
    const actionNodeId = path.getAttribute('data-action-node-id');
    if (!optionId || !nodeId || !actionNodeId) return;
    const optionElement = document.querySelector(`#o_${nodeId}_${optionId}`);
    const actionNodeElement = document.querySelector(`#n_${actionNodeId}`);
    const commonAncestor = this.flow?.nativeElement;
    const svg = path.closest('svg');
    if (!svg || !optionElement || !actionNodeElement || !commonAncestor) return;

    const optionRect = optionElement.getBoundingClientRect();
    const nodeRect = actionNodeElement.getBoundingClientRect();
    const ancestorRect = commonAncestor.getBoundingClientRect();

    const scrollLeft = commonAncestor.scrollLeft;
    const scrollTop = commonAncestor.scrollTop;

    const x1 = optionRect.right - ancestorRect.left + scrollLeft;
    const y1 =
      optionRect.top + optionRect.height / 2 - ancestorRect.top + scrollTop;
    const x2 = nodeRect.left - ancestorRect.left + scrollLeft;
    const y2 = nodeRect.top + 24 - ancestorRect.top + scrollTop;

    const svgX = Math.min(x1, x2);
    const svgY = Math.min(y1, y2);

    const padding = 20;
    const svgWidth = Math.max(Math.abs(x2 - x1), 1) + padding * 2;
    const svgHeight = Math.abs(y2 - y1) + padding * 2;

    svg.style.top = `${svgY - padding}px`;
    svg.style.left = `${svgX - padding}px`;
    svg.style.width = `${svgWidth}px`;
    svg.style.height = `${svgHeight}px`;

    // Define control points for the Bezier curve
    const offset = 70;
    const cx1 = x2 > x1 ? (x1 + x2) / 2 : x1 + offset;
    const cy1 = y1;
    const cx2 = x2 > x1 ? (x1 + x2) / 2 : x2 - offset;
    const cy2 = y2;

    path.setAttribute(
      'd',
      `M ${x1 - svgX + padding} ${y1 - svgY + padding} C ${
        cx1 - svgX + padding
      } ${cy1 - svgY + padding}, ${cx2 - svgX + padding} ${
        cy2 - svgY + padding
      }, ${x2 - svgX + padding} ${y2 - svgY + padding}`,
    );
  }

  setTimeoutDuration(e: any) {
    if (!this.flowService.flow) return;
    const active = e.target.active;
    this.flowListService.updateFlow(this.flowService.flow.uid, {
      timeoutDuration: active ? 900 : null,
    });
  }

  alignFlow() {
    this.isAutoAligning = true;
    const startingNode = this.flowService.nodes.find(
      (node) => node.uid === this.flowService.flow?.startingNode,
    );
    if (!startingNode) return;

    const visited = new Set<string>();
    const layerHeights: Map<number, number> = new Map(); // Track the max Y-coordinate for each layer

    const alignRecursive = (
      node: INode,
      x: number,
      y: number,
      layer: number,
    ) => {
      if (visited.has(node.uid)) return;
      visited.add(node.uid);

      const nodeElement = document.getElementById(`n_${node.uid}`);
      if (!nodeElement) return;

      const gridSize = 8;
      const padding = 60;
      const border = 4;
      const newX = x / gridSize + border;
      const newY = y / gridSize + border;

      if (node.coords?.x !== newX || node.coords?.y !== newY) {
        node.coords = { x: newX, y: newY };
        this.flowService.setNode(this.flowService.flow?.uid, node.uid, node);
      }

      const nodeRect = nodeElement.getBoundingClientRect();
      const nextX = x + nodeRect.width + padding;
      let nextY = layerHeights.get(layer) || y;
      let maxYInLayer = nextY;

      for (const response of node.responseOptions) {
        const childNode = this.flowService.nodes.find(
          (n) => n.uid === response.actionNode,
        );
        if (!childNode) continue;

        alignRecursive(childNode, nextX, nextY, layer + 1);

        const childElement = document.getElementById(`n_${childNode.uid}`);
        if (!childElement) continue;

        const childRect = childElement.getBoundingClientRect();
        nextY += childRect.height + padding;
        maxYInLayer = Math.max(nextY, maxYInLayer);
      }
      layerHeights.set(layer, maxYInLayer);
    };

    alignRecursive(startingNode, 0, 0, 0);

    setTimeout(() => {
      this.drawConnectionLines();
      this.isAutoAligning = false;
    }, 150);
  }
}
