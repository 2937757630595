<section class="card min-h-full">
  <!-- header -->
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded">local_fire_department</span>Chips em
    aquecimento ({{ warmupChannels.length }}/{{ userService.getWarmupLimit() }})
    <!-- add creates a channel with deletedAt and startedWarmingUpAt -->
    <button
      class="ml-auto tertiary !px-1 md:!px-3"
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
      [disabled]="warmupChannels.length >= userService.getWarmupLimit()"
    >
      <span class="material-symbols-rounded m-auto text-black/60"> add </span>
      @if (sharedService.screenSize !== 's') {
        Adicionar
      }
    </button>
  </h2>
  <!-- error -->
  @if (channelsService.warmupChannels.length >= userService.getWarmupLimit()) {
    <p
      class="flex-col md:flex-row p-3 border border-solid rounded-md bg-red-400/10 flex md:items-center gap-3 border-red-400"
    >
      <b class="flex-1"
        >O limite de aquecimento para seu plano ({{
          userService.getWarmupLimit()
        }}) foi atingido.</b
      >
      <a
        class="no-underline w-full md:w-fit"
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+contratar+mais+aquecimento"
      >
        <button class="w-full">Adquirir mais aquecimento</button>
      </a>
    </p>
  }

  <!-- body -->
  @if (warmupChannels.length > 0) {
    <p class="text-balance text-black/60">
      Registre chips novos para aquecimento automático e diminua os riscos de
      banimento no WhatsApp.
      <a
        href="https://home.blu.direct/blog/visao-geral-aquecimento-de-chips"
        target="_blank"
        class="font-bold text-accent-600"
        >Saiba mais</a
      >
    </p>
    <kor-table columns="2fr 1fr 1fr 82px 64px" readonly>
      <kor-table-row slot="header">
        <kor-table-cell head>Número</kor-table-cell>
        <kor-table-cell head>Início</kor-table-cell>
        <kor-table-cell head>Temperatura</kor-table-cell>
        <kor-table-cell head>Ativo</kor-table-cell>
      </kor-table-row>
      @for (channel of warmupChannels; track channel) {
        <kor-table-row>
          <kor-table-cell
            ><b>{{
              channel.numbers[0]
                | mask: '+00 (00) 0000-0000||+00 (00) 0 0000-0000' || '–'
            }}</b>
          </kor-table-cell>
          <kor-table-cell>{{
            channel.startedWarmingUpAt | date: 'd MMM YYYY' || '–'
          }}</kor-table-cell>
          <kor-table-cell class="capitalize">
            <div
              class="flex gap-1 items-center p-1 pr-2 rounded-md"
              [ngClass]="{
                'text-red-600 bg-red-600/10': channel.temperature === 'quente',
                'text-yellow-600 bg-yellow-600/10':
                  channel.temperature === 'morno',
                'text-blue-600 bg-blue-600/10': channel.temperature === 'frio'
              }"
            >
              <span class="material-symbols-rounded">device_thermostat </span>
              {{ channel.temperature || '–' }}
            </div>
          </kor-table-cell>
          <kor-table-cell>
            @if (channel.status === 'CONNECTED') {
              <span class="material-symbols-rounded block text-green-600">
                check_circle
              </span>
            } @else {
              <button
                class="tertiary px-3"
                (click)="$event.stopPropagation()"
                [routerLink]="[
                  { outlets: { modal: ['activate', channel.uid] } }
                ]"
              >
                Ativar
              </button>
            }
          </kor-table-cell>
          <kor-table-cell alignment="right">
            <button class="secondary" (click)="pauseWarmup(channel)">
              <span class="material-symbols-rounded"> delete </span>
            </button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Aqueça seu primeiro chip</h1>
      <p class="leading-snug text-black/60">
        Registre chips novos para aquecimento automático e diminua os riscos de
        banimento no WhatsApp.
      </p>
      <a
        href="https://home.blu.direct/blog/visao-geral-aquecimento-de-chips"
        target="_blank"
        class="no-underline"
      >
        <button class="secondary">Saiba mais</button>
      </a>
    </div>
  }
</section>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
