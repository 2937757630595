import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { CsvImportService } from '@app/services/csv-import.service';
import { SendService } from '@app/services/send.service';
import { SharedService } from '@app/services/shared.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'blu-upload-contacts',
  templateUrl: './upload-contacts.component.html',
  styleUrls: ['./upload-contacts.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class UploadContactsComponent {
  importErrorMessage = new Subject<string>();
  isLoading = false;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    private csvImportService: CsvImportService,
    private sendService: SendService,
    public analyticsService: AnalyticsService,
  ) {}

  async handleCsvImport(event: Event): Promise<void> {
    this.setLoading(true);
    // reset recipients if already chosen
    this.sendService.setRecipients([]);

    // get file
    const files = (event.target as HTMLInputElement).files;
    const item = files?.item(0);
    if (!files || !item) return;

    // post contacts and get array of new contacts
    let response;
    try {
      response = await this.csvImportService.importCsv(item);
    } catch (error) {
      this.importErrorMessage.next(
        'Não foi possível importar os contatos, verifique se o arquivo está de acordo com as recomendações e tente novamente.',
      );
      return;
    } finally {
      this.setLoading(false);
    }

    // set source to be csv
    this.sendService.recipientSource = 'csv';
    this.sendService.csvHeaders = response.headers;

    // push each contact's recipient to recipients list
    response.contacts.forEach((contact) =>
      this.sendService.addRecipient(contact.recipient),
    );

    // then move to next step
    this.router.navigateByUrl('/user/send/trigger');
  }

  setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
