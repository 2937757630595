import { Injectable } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  rating = -1;
  didRate = false;
  comment = '';

  constructor(
    private firestore: SharedFirestoreService,
    private userService: UserService,
  ) {}

  submitFeedback() {
    this.didRate = true;
    this.firestore.addDoc(
      'feedback',
      {
        rating: this.rating + 1,
        comment: this.comment,
        createdAt: serverTimestamp(),
        createdBy: this.userService.user()?.uid || '',
      },
      false,
    );
  }
}
