import { Component, OnInit } from '@angular/core';
import {
  onSnapshot,
  orderBy,
  where,
  query,
  collection,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import {
  FirestoreCollections,
  IBluCampaign,
} from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'blu-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss'],
})
export class CampaignsListComponent implements OnInit {
  campaigns: IBluCampaign[] = [];

  constructor(
    private firestore: SharedFirestoreService,
    public userService: UserService,
    public sharedService: SharedService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const org = this.userService.organization();
    if (!org) {
      this.router.navigate(['/sign-in/admin']);
      return;
    }
    this.getCampaigns(org.uid);
  }

  // get campaigns from user
  private async getCampaigns(orgId: string): Promise<void> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const campaignsQuery = query(
      collection(this.firestore.afs, FirestoreCollections.Campaign),
      where('organization', '==', orgId),
      where('createdAt', '>', today),
      where('status', '!=', 'paused'),
      orderBy('createdAt', 'desc'),
    );

    onSnapshot(campaignsQuery, async (campaignsQuerySnap) => {
      if (!campaignsQuerySnap.size) await this.sharedService.sleep(300);
      this.campaigns = await Promise.all(
        campaignsQuerySnap.docs.map(async (campaignSnap) => {
          const campaign = campaignSnap.data() as IBluCampaign;
          campaign.id = campaignSnap.id;
          const user = await this.userService.getUserById(campaign.sentBy);
          if (!user) return campaign;
          campaign.userName = user.name;
          return campaign;
        }),
      );
    });
  }
}
