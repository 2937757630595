import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FirestoreCollections,
  ICampaign,
  ITemplate,
  OnboardingStepKey,
} from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { BluApiService } from '../../../services/blu-api.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SendService } from '../../../services/send.service';
import { UserService } from '../../../services/user.service';
import { templateCampaignDelay } from '../../user/send/trigger/trigger.component';
import { Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { OnboardingService } from '@app/services/onboarding.service';

@Component({
  selector: 'blu-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss'],
})
export class TemplateSendComponent implements OnInit {
  templateName?: string;
  templateOrgId?: string;

  constructor(
    private route: ActivatedRoute,
    private firestore: SharedFirestoreService,
    private userService: UserService,
    public sendService: SendService,
    public analyticsService: AnalyticsService,
    public bluApi: BluApiService,
    private onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  async initialize(): Promise<void> {
    await this.getTemplate(this.route.parent?.snapshot.params['id']);
    // wait 30 seconds for contacts to load
    await new Promise((resolve) => setTimeout(resolve, 30000));
    this.sendService.addAllUserContactsToRecipients();
  }

  private async getTemplate(id: string): Promise<void> {
    const templateDocData = await this.firestore.getDoc<ITemplate>(
      FirestoreCollections.Template,
      id,
    );
    if (!templateDocData) return;
    this.templateName = templateDocData.name;
    this.templateOrgId = templateDocData.organization;
    this.sendService.content = {
      text: templateDocData.text,
      media: templateDocData.media,
      poll: {
        name: templateDocData.poll?.name || null,
        options: templateDocData.poll?.options || [],
      },
      viewOnce: false, // template doesn't have viewOnce
    };
  }

  private async createCampaign(): Promise<string> {
    const body = {
      createdAt: serverTimestamp() as Timestamp,
      sentBy: this.userService.user()?.uid,
      organization: this.templateOrgId || null,
      recipients: this.sendService.recipients,
      text: this.sendService.content.text,
      media: this.sendService.content.media,
      poll: {
        name: this.sendService.content.poll?.name || null,
        options: this.sendService.content.poll?.options || [],
      },
      messagesSent: 0,
      messagesTotal: this.sendService.recipients.length,
      messageDelay: templateCampaignDelay,
      intervalSettings: {
        messageCount: 10,
        interval: 30,
      },
      viewOnce: this.sendService.content.viewOnce,
      recipientSource: 'template',
    } as Partial<ICampaign>;

    const campaignId = this.bluApi.serverId;
    await this.firestore.setDoc(
      FirestoreCollections.Campaign,
      campaignId,
      body,
      { merge: false },
    );

    this.sendService.campaign.uid = campaignId;
    return campaignId;
  }

  async send(): Promise<void> {
    await this.sendService.setVariableValues();
    const campaignUid = await this.createCampaign();
    this.bluApi.startCampaign(campaignUid);
    this.onboardingService.completeStep(OnboardingStepKey.usedTemplateLink);
  }
}
