import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriesService } from '@app/services/categories.service';
import { OperatorsService } from '@app/services/operators.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { IChatCategory } from 'libs/shared/src/lib/interfaces';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'blu-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  category?: IChatCategory;

  constructor(
    public route: ActivatedRoute,
    private categoriesService: CategoriesService,
    private userService: UserService,
    public operatorListService: OperatorsService,
    public sharedService: SharedService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.category = await this.initCategory();
      return;
    }
    let category = await this.categoriesService.getCategory(id);
    if (!category) category = await this.initCategory();
    this.category = category;
    const orgId = this.userService.organization()?.uid;
    if (orgId && !this.operatorListService.operators.length) {
      this.operatorListService.getOperators(orgId);
    }
  }

  private async initCategory() {
    const org = this.userService.organization();
    if (!org) return;
    return {
      label: undefined,
      organization: org.uid,
      operators: [],
    } as IChatCategory;
  }

  setName(tar: EventTarget | null): void {
    if (!this.category) return;
    this.category.label = (tar as HTMLInputElement).value;
  }

  addOperatorToCategory(operatorId: string) {
    if (!this.category?.operators) return;
    const index = this.category.operators.indexOf(operatorId);
    if (index !== -1) {
      this.category.operators.splice(index, 1);
    } else {
      this.category?.operators.push(operatorId);
    }
  }

  async delete(): Promise<void> {
    if (!this.category?.uid) return;
    await this.categoriesService.deleteCategory(this.category.uid);
    this.sharedService.closeModal(this.route);
  }

  async save(): Promise<void> {
    if (!this.category) return;
    if (this.category.uid) {
      await this.categoriesService.updateCategory(
        this.category.uid,
        this.category,
      );
    } else await this.categoriesService.createCategory(this.category);
    this.sharedService.closeModal(this.route);
  }
}
