<!-- content -->
<img src="assets/images/404.png" />
<h1>{{ this.error?.title }}</h1>
<p>{{ this.error?.message }}</p>
<!-- button -->
<kor-button
  [routerLink]="'/sign-in'"
  label="Entrar novamente"
  (click)="analyticsService.logEvent('error_sign-in_click')"
></kor-button>

<!-- <ng-template #defaultMessage>
  Aconteceu um erro inesperado. Caso o WhatsApp esteja fora do ar, espere 15
  minutos e entre novamente para continuar usando o blü.
</ng-template> -->
