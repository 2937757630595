<nav class="blu-tabs">
  @for (tab of tabs; track tab) {
    <button
      class="blu-tab-item flex-1"
      [ngClass]="{
        'blu-tab-item-active': router.url.endsWith('/sign-in/' + tab.path)
      }"
      routerLink="/sign-in/{{ tab.path }}"
      (click)="
        analyticsService.logEvent('sign-in_admin_' + tab.id + '-tab_click')
      "
    >
      {{ tab.label }}
    </button>
  }
</nav>

<router-outlet></router-outlet>
