@if (category) {
  <shared-modal [mobile]="sharedService.screenSize !== 'l'">
    <!-- header -->
    <h2 slot="header">Editar categoria</h2>

    <!-- body -->
    <kor-input
      label="Nome"
      [value]="category.label"
      (input)="setName($event.target)"
    ></kor-input>

    <hr />

    <h2>Atendentes</h2>
    <div class="flex flex-col gap-2">
      @for (operator of operatorListService.operators; track operator) {
        <kor-checkbox
          [label]="operator.name"
          [active]="category.operators.includes(operator.uid!)"
          (click)="
            $event.stopImmediatePropagation();
            addOperatorToCategory(operator.uid)
          "
        >
          <p slot="functions" class="text-sm my-auto text-black/60">
            {{ operator.email }}
          </p>
        </kor-checkbox>
      }
    </div>

    <!-- footer -->
    @if (category.uid) {
      <kor-button
        class="mr-auto"
        slot="footer"
        color="tertiary"
        label="Deletar"
        (click)="
          delete(); analyticsService.logEvent('admin_delete_category_click')
        "
      ></kor-button>
    }
    <kor-button
      slot="footer"
      color="secondary"
      label="Cancelar"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('admin_close_category_modal')
      "
    >
    </kor-button>
    <kor-button
      slot="footer"
      label="Confirmar"
      [disabled]="!category.label"
      (click)="save(); analyticsService.logEvent('admin_save_category_click')"
    >
    </kor-button>
  </shared-modal>
}
