<!-- trigger template if campaign has not been created -->
@if (sendService.campaign.uid) {
  @if (sendService.progress < 100) {
    <!-- running -->
    @if (sendService.progress > 0) {
      <h1>
        Enviando mensagens ({{ sendService.campaign.messagesSent }} /
        {{ sendService.recipients.length }})
      </h1>
      <kor-progress-bar [value]="sendService.progress"></kor-progress-bar>
    } @else {
      <!-- TODO: rework this -->
      Seu disparo foi inciado com sucesso
    }
  } @else {
    <!-- success -->
    <h1>Disparo concluído!</h1>
    <kor-progress-bar
      value="100"
      color="rgb(var(--functional-green))"
    ></kor-progress-bar>
    <p>
      {{ sendService.campaign.messagesSent }} mensagens enviadas com sucesso.
    </p>
    <br />
  }
} @else {
  @if (sendService.recipients.length > 0) {
    <h1>{{ templateName }}</h1>
    <p>{{ sendService.recipients.length }} recipientes</p>
    <!-- body -->
    <div class="card">
      <!-- media -->
      <!-- TODO: audio and video support -->
      @for (media of sendService.content.media; track media) {
        <img [src]="media" width="100%" />
      }
      <!-- text -->
      <p [innerHTML]="sendService.content.text || '' | markdown"></p>
    </div>
    <kor-button
      label="Iniciar envio"
      (click)="send(); analyticsService.logEvent('template_send_button_click')"
    ></kor-button>
  } @else {
    <!-- spinner -->
    <kor-spinner label="Carregando contatos..."></kor-spinner>
  }
}
