import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelsService } from '@app/services/channels.service';
import { OperatorsService } from '@app/services/operators.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { IChannel } from 'libs/shared/src/lib/interfaces';

@Component({
  selector: 'blu-add-warmup',
  templateUrl: './add-warmup.component.html',
  styleUrls: ['./add-warmup.component.scss'],
})
export class AddWarmupComponent implements OnInit {
  channel?: IChannel;
  number = {
    countryCode: '55',
    areaCode: '',
    number: '',
  };

  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public operatorListService: OperatorsService,
    public userService: UserService,
    public channelsService: ChannelsService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.channel = await this.initChannel();
  }

  private async initChannel() {
    const org = this.userService.organization();
    if (!org) throw new Error('No organization found');
    return {
      name: undefined,
      createdAt: new Date(),
      deletedAt: new Date(),
      startedWarmingUpAt: new Date(),
      operators: [],
      numbers: [],
      organization: org.uid,
      activeFlow: null,
    } as IChannel;
  }

  setName(tar: EventTarget | null): void {
    if (!this.channel) return;
    this.channel.name = (tar as HTMLInputElement).value;
  }

  setNumber(
    key: 'countryCode' | 'areaCode' | 'number',
    event: Event,
    maxlength: number,
  ): void {
    if (!this.channel) return;
    const tar = event.target as HTMLInputElement;
    const number = tar.value.replace(/\D/g, '').substring(0, maxlength);
    this.number[key] = number ? number : '';
    tar.value = this.number[key];
    if (
      !this.number.countryCode ||
      !this.number.areaCode ||
      !this.number.number
    ) {
      return;
    }
    const jointNumber = `${this.number.countryCode}${this.number.areaCode}${this.number.number}`;
    const jointNumberExtra9 = `${this.number.countryCode}${this.number.areaCode}9${this.number.number}`;
    this.channel.numbers = [jointNumber, jointNumberExtra9];
  }

  async save(): Promise<void> {
    if (!this.channel) throw new Error('No channel found');
    await this.channelsService.createWarmup(this.channel);
    this.sharedService.closeModal(this.route);
  }
}
