import { Component, inject } from '@angular/core';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SendService } from '../../../../services/send.service';
import { UserService } from '../../../../services/user.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import {
  FirestoreCollections,
  OnboardingStepKey,
} from 'libs/shared/src/lib/interfaces';
import { BluApiService } from '@app/services/blu-api.service';
import { serverTimestamp } from '@angular/fire/firestore';
import { OnboardingService } from '@app/services/onboarding.service';

export const importedCampaignDelay = 2;
export const csvCampaignDelay = 15;
export const templateCampaignDelay = 10;

@Component({
  selector: 'blu-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss'],
})
export class TriggerComponent {
  sendService = inject(SendService);
  userService = inject(UserService);
  analyticsService = inject(AnalyticsService);
  private firestore = inject(SharedFirestoreService);
  private bluApi = inject(BluApiService);
  private onboardingService = inject(OnboardingService);

  selectedPreset?: string;
  settingsPresets = [
    {
      name: 'Rápido',
      icon: 'fast',
      description: 'Contatos orgânicos',
      scores: {
        speed: 5,
        safety: 1,
      },
      delay: 3,
      intervalSettings: {
        messageCount: 100,
        interval: 10,
      },
    },
    {
      name: 'Seguro',
      icon: 'safe',
      description: 'Listas importadas',
      scores: {
        speed: 3,
        safety: 5,
      },
      delay: 15,
      intervalSettings: {
        messageCount: 200,
        interval: 120,
      },
    },
  ];

  delay: number = importedCampaignDelay;
  intervalSettings = {
    messageCount: 500,
    interval: 10,
  };
  estimativeTime = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  isRunning = false;

  constructor() {
    // select preset on load depending on recipients source
    const defaultPreset =
      this.sendService.recipientSource === 'csv'
        ? this.settingsPresets[1]
        : this.settingsPresets[0];
    this.applyPreset(defaultPreset);
  }

  applyPreset(preset: any): void {
    this.selectedPreset = preset.name;
    this.delay = preset.delay;
    this.intervalSettings = preset.intervalSettings;
    this.setEstimativeTime();
  }

  getElementValue(e: Event): number {
    const el = e.target as HTMLInputElement;
    const val = el.value;
    return Number(val);
  }

  countMessagesPerRecipient(): number {
    let numMessages = 0;
    if (this.sendService.content.text) numMessages++;
    if (this.sendService.content.media.length) {
      numMessages += this.sendService.content.media.length;
    }
    if (this.sendService.content.poll.options.length) numMessages++;
    return numMessages;
  }

  setEstimativeTime() {
    const numMessages = this.countMessagesPerRecipient();
    const intraMessagesPause = 0.7 * (numMessages - 1);

    const remainingLongPauses = Math.floor(
      (this.sendService.recipients.length -
        1 -
        this.sendService.campaign.messagesSent) /
        this.intervalSettings.messageCount,
    );
    const shortPauses =
      (this.sendService.recipients.length -
        this.sendService.campaign.messagesSent) *
      (this.delay + intraMessagesPause);
    const longPauses = remainingLongPauses * this.intervalSettings.interval;
    let totalDuration = shortPauses + longPauses;
    if (!totalDuration || totalDuration == Infinity) totalDuration = 0;
    this.estimativeTime.hours = Math.floor(totalDuration / (60 * 60));
    totalDuration -= this.estimativeTime.hours * 60 * 60;
    this.estimativeTime.minutes = Math.floor(totalDuration / 60);
    totalDuration -= this.estimativeTime.minutes * 60;
    this.estimativeTime.seconds = Math.floor(totalDuration);
  }

  async send(): Promise<void> {
    if (this.isRunning) return;
    this.isRunning = true;
    await this.sendService.setVariableValues();
    this.onboardingService.completeStep(OnboardingStepKey.createdCampaign);
    const campaignUid = await this.createCampaign();
    this.bluApi.startCampaign(campaignUid);
    this.setLastCampaignDate();
  }

  private async setLastCampaignDate() {
    const orgId = this.userService.organization()?.uid;
    if (!orgId) return;
    await this.firestore.setDoc(
      FirestoreCollections.Organization,
      orgId,
      { lastCampaignDate: new Date() },
      { merge: true },
    );
  }

  private async createCampaign(): Promise<string> {
    const user = this.userService.user();
    if (!user || !this.sendService.recipients || !this.sendService.content)
      return '';
    // compose campaign
    const body = {
      createdAt: serverTimestamp(),
      sentBy: user.uid,
      organization: user.organization,
      recipients: this.sendService.recipients,
      text: this.sendService.content.text || null,
      media: this.sendService.content.media || [],
      poll: this.sendService.content.poll,
      messagesSent: 0,
      messagesTotal: this.sendService.recipients.length,
      messageDelay: this.delay,
      intervalSettings: null as any,
      viewOnce: this.sendService.content.viewOnce,
      recipientSource: this.sendService.recipientSource,
    };

    if (this.intervalSettings.interval > 0) {
      body.intervalSettings = this.intervalSettings;
    }

    const campaignId = this.bluApi.serverId;
    await this.firestore.setDoc(
      FirestoreCollections.Campaign,
      campaignId,
      body,
      { merge: false },
    );

    this.sendService.campaign.uid = campaignId;
    return campaignId;
  }

  reloadPage(): void {
    location.reload();
  }
}
