<div class="card">
  <!-- ranking -->
  <h2>
    Ranking mensal
    <kor-switch>
      <kor-switch-item
        label="Mensagens"
        (click)="selectedChart = 'messages'"
        [active]="selectedChart === 'messages'"
      ></kor-switch-item>
      <kor-switch-item
        label="Disparos"
        (click)="selectedChart = 'campaigns'"
        [active]="selectedChart === 'campaigns'"
      ></kor-switch-item>
    </kor-switch>
  </h2>

  <kor-table readonly>
    @for (user of rankedUsers[selectedChart]; track user; let i = $index) {
      <kor-table-row>
        <!-- icon or rank -->
        <kor-table-cell class="icon">
          @if (i <= 2) {
            <kor-icon icon="url(assets/icons/rank_{{ i + 1 }}.png)"></kor-icon>
          } @else {
            <span class="rank">#{{ i + 1 }}</span>
          }
        </kor-table-cell>
        <!-- name -->
        <kor-table-cell class="name">
          <b>{{ user.name }}</b>
        </kor-table-cell>
        <!-- value -->
        <kor-table-cell class="value">
          {{ user.value }}
        </kor-table-cell>
      </kor-table-row>
    }
  </kor-table>
</div>
