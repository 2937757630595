<!-- hack to initialize the variable qr on a larger scope, the object always evaluates to true -->
<!-- TODO: replace ngIf syntax with @if -->
<div
  class="qr-card"
  *ngIf="{
    qr: bluApi.qr$ | async,
    isLoading: bluApi.loadingIndicator$ | async,
    qrError: bluApi.qrError
  } as observable"
>
  @if (observable.qr && !observable.isLoading && !observable.qrError) {
    <qrcode
      class="grid-overlap"
      [qrdata]="observable.qr"
      [width]="200"
    ></qrcode>
  } @else {
    <!-- show spinner while QR is not generated -->
    <div class="grid-overlap">
      @if (!observable.qrError) {
        <kor-spinner
          [label]="
            (observable.isLoading ? 'Inicializando sessão' : 'Gerando código')
              | loading
              | async
          "
          [class]="observable.isLoading ? 'spinner-overlap' : ''"
          style="white-space: break-spaces"
        ></kor-spinner>
      } @else {
        O código QR expirou. Atualize a página para recarregar.
        <kor-button label="Atualizar" (click)="reloadPage()"></kor-button>
      }
    </div>
  }
</div>
<p>Escaneie o código acima no <b>WhatsApp</b> até receber a confirmação.</p>
