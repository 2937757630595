import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  transform(value: string): any {
    const renderer = new marked.Renderer();
    marked.setOptions({
      renderer,
      gfm: true,
      breaks: true,
      sanitize: false,
    });

    if (!value) return value;
    // protect against XSS
    value = value
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    // Replace *text* with <strong>text</strong>
    value = value.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');

    // Replace _text_ with <em>text</em> for italic
    value = value.replace(/_([^_]+)_/g, '<em>$1</em>');

    // Replace ~text~ with <del>text</del> for strikethrough
    value = value.replace(/~([^~]+)~/g, '<del>$1</del>');

    // Replace ```text``` with <code>text</code> for monospace
    value = value.replace(/```([^`]+)```/g, '<code>$1</code>');

    // Replace newline characters with <br>
    value = value.replace(/\n/g, '<br>');

    // Use marked library to convert the remaining markdown to HTML
    return marked(value);
  }
}
