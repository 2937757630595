import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WSError } from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';

@Component({
  selector: 'blu-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  error?: { type: string; title: string; message: string };
  errors = [
    {
      type: WSError.Logout,
      title: 'Conexão interrompida',
      message:
        'Seu aparelho foi desconectado no aplicativo do WhatsApp, conecte novamente para continuar usando o blü.',
    },
    {
      type: WSError.Forbidden,
      title: 'Número não cadastrado',
      message:
        'Seu número não tem permissão para acessar o blü, peça ao seu coordenador para adicioná-lo.',
    },
    {
      type: WSError.TemplateForbidden,
      title: 'Erro no disparo',
      message:
        'Este número já realizou um disparo. Adicione-o como colaborador caso queira fazer novamente.',
    },
    {
      type: WSError.SendMessages,
      title: 'Disparo interrompido',
      message:
        'Aconteceu um erro durante o disparo, entre novamente para continuar usando o blü.',
    },
    {
      type: WSError.Subscription,
      title: 'Nenhuma assinatura ativa',
      message:
        'Sua organização não tem uma assinatura ativa, peça ao seu coordenador para renová-la para continuar usando o blü.',
    },
    {
      type: WSError.WrongNumber,
      title: 'Número incorreto',
      message:
        'O número que escaneou o código QR não é o mesmo que foi registrado no canal. Verifique que o número foi digitado corretamente e tente novamente.',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) =>
        (this.error = this.errors.find(
          (error) => error.type === params['errorType'],
        )),
    );
  }
}
