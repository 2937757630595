import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  screenSize: string | undefined;
  @Output() scrollBottom = new EventEmitter();

  constructor(private router: Router) {}

  setScreenSize(): void {
    this.screenSize = window.innerWidth <= 768 ? 's' : 'l';
  }

  getCampaignStatusIcon(status: string | undefined): {
    icon: string;
    color: string;
  } {
    switch (status) {
      case 'running':
        return { icon: 'schedule_send', color: 'rgb(var(--functional-blue))' };
      case 'success':
        return { icon: 'check_circle', color: 'rgb(var(--functional-green))' };
      case 'error':
        return { icon: 'error', color: 'rgb(var(--functional-red))' };
      default:
        return { icon: 'help', color: 'rgb(var(--text-2))' };
    }
  }

  closeModal(route: ActivatedRoute): void {
    this.router.navigate([{ outlets: { modal: null } }], {
      relativeTo: route.parent,
    });
  }

  // get a date from X days ago
  public getPastDate(daysAgo: number): Date {
    const today = new Date();
    const pastDate = new Date(new Date().setDate(today.getDate() - daysAgo));
    pastDate.setHours(0, 0, 0, 0);
    return pastDate;
  }

  sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
