import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { OperatorsService } from '@app/services/operators.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'blu-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss'],
})
export class OperatorsComponent implements OnInit {
  constructor(
    private router: Router,
    public userService: UserService,
    public sharedService: SharedService,
    public operatorsService: OperatorsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const org = this.userService.organization();
    if (!org) {
      this.router.navigate(['/sign-in/admin']);
      return;
    }
    this.operatorsService.getOperators(org.uid);
  }
}
