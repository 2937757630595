import { Component } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'blu-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  constructor(
    public alertService: AlertService,
    public sharedService: SharedService,
  ) {}

  confirm() {
    this.alertService.confirmCallback?.();
  }

  cancel() {
    this.alertService.cancelCallback?.();
  }
}
