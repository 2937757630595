import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'libs/shared/src/lib/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private http: HttpClient) {}

  async formatText(body: string): Promise<string> {
    const response = await firstValueFrom(
      this.http.post<any>(environment.cloudFunctionsApiUrl + '/formatMessage', {
        message: body,
      }),
    );
    return response.formattedMessage[0].text.trim();
  }
}
