@if (campaign) {
  <div
    class="card"
    [style.height]="sharedService.screenSize === 'l' ? 'auto' : '100%'"
    [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
  >
    <header class="header">
      <h2>Detalhes da campanha</h2>
      <kor-icon
        button
        icon="close"
        (click)="sharedService.closeModal(route)"
      ></kor-icon>
    </header>

    <p class="text" [innerHTML]="campaign.text || '' | markdown"></p>
    @if (campaign.media.length) {
      <blu-media-input
        [(media)]="campaign.media"
        [readonly]="true"
      ></blu-media-input>
    }
    @if (campaign.poll.name) {
      <div class="flex flex-col gap-2">
        <kor-divider></kor-divider>
        {{ campaign.poll.name }}
        <ul>
          @for (option of campaign.poll.options; track option) {
            <li>{{ option }}</li>
          }
        </ul>
      </div>
    }
  </div>
}
