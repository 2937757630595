import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SharedService } from './shared.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(
    private firestore: SharedFirestoreService,
    private sharedService: SharedService,
    public userService: UserService,
  ) {}

  public async getLeadsForTemplate(templateId: string): Promise<number> {
    const orgId = this.userService.organization()?.uid;
    if (!orgId) throw new Error('Organization not found');
    const dateFrom = this.sharedService.getPastDate(30);
    const usersQuerySnap = await this.firestore.getDocs(
      'user',
      where('organization', '==', orgId),
      where('sourceTemplate', '==', templateId),
      where('createdAt', '>=', dateFrom),
    );
    return usersQuerySnap.size;
  }
}
