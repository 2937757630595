<!-- today campaigns -->
<div class="card">
  <h2>Disparos feitos hoje</h2>
  @if (campaigns.length > 0) {
    <kor-table columns="1fr auto auto">
      @for (campaign of campaigns; track campaign) {
        <kor-table-row
          [routerLink]="[{ outlets: { modal: ['campaign', campaign.id] } }]"
        >
          <kor-table-cell>
            <div class="title">
              <kor-icon
                [icon]="
                  sharedService.getCampaignStatusIcon(campaign.status).icon
                "
                [color]="
                  sharedService.getCampaignStatusIcon(campaign.status).color
                "
                [title]="campaign.id"
              ></kor-icon>
              <p>
                <b>{{ campaign.userName }}</b>
                {{ campaign.messagesSent }}/{{ campaign.messagesTotal }}
                mensagens
              </p>
            </div>
          </kor-table-cell>
          <kor-table-cell>
            {{ campaign.createdAt.toDate() | date: 'HH:mm' }}
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- else, show empty state -->
    <kor-empty-state icon="list" label="Nenhum disparo realizado até o momento">
    </kor-empty-state>
  }
</div>
