<div class="card min-h-full">
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded"> lock_open </span>
    Chaves de disparo ({{ users.length }}/{{ getUserLimit() }})
    <button
      [routerLink]="[{ outlets: { modal: ['add-user', ''] } }]"
      class="ml-auto tertiary !px-1 md:!px-3"
      [disabled]="users.length >= getUserLimit()"
      (click)="analyticsService.logEvent('admin_add_user_button_click')"
    >
      <span class="material-symbols-rounded m-auto text-black/60"> add </span>
      @if (sharedService.screenSize !== 's') {
        Adicionar
      }
    </button>
  </h2>
  <!-- error -->
  @if (users.length >= getUserLimit()) {
    <p
      class="flex-col md:flex-row p-3 border border-solid rounded-md bg-red-400/10 flex md:items-center gap-3 border-red-400"
    >
      <b class="flex-1"
        >O limite de chaves de disparo para seu plano ({{ getUserLimit() }}) foi
        atingido.</b
      >
      <a
        class="no-underline w-full md:w-fit"
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+mais+chaves+de+acesso"
      >
        <button class="w-full">Adquirir mais acessos</button>
      </a>
    </p>
  }

  <!-- body -->
  @if (users.length > 0) {
    <p class="text-balance text-black/60">
      Para realizar disparos em massa, adicione uma chave de disparo para cada
      número do WhatsApp de seu time.
      <a
        href="https://home.blu.direct/blog/visao-geral-disparos"
        target="_blank"
        class="font-bold text-accent-600"
        >Saiba mais</a
      >
    </p>
    <!-- tags -->
    <div class="flex gap-1 flex-wrap">
      <kor-tag
        class="border-dashed cursor-pointer hover:bg-black/10"
        label="Criar categoria"
        icon="add"
        [routerLink]="[{ outlets: { modal: ['add-user-tag'] } }]"
        (click)="analyticsService.logEvent('admin_add-user-tag_button_click')"
      ></kor-tag>

      @for (tag of userService.organization()?.userTags; track tag) {
        <kor-tag [label]="tag" removable (remove)="deleteTag(tag)"></kor-tag>
      }
    </div>
    <!-- list -->
    <kor-table>
      <kor-table-row class="flex" slot="header">
        <kor-table-cell head class="flex-1">Nome</kor-table-cell>
        <kor-table-cell head grid-cols="10">Último disparo</kor-table-cell>
      </kor-table-row>
      @for (user of users; track user) {
        <kor-table-row
          class="flex"
          [routerLink]="[{ outlets: { modal: ['add-user', user.uid] } }]"
          (click)="analyticsService.logEvent('admin_user_row_click')"
        >
          <kor-table-cell class="flex-1">
            <b class="flex items-center gap-1 flex-wrap"
              >{{ user.name }}
              @for (tag of user.tags; track tag) {
                <kor-tag [label]="tag" class="py-0 px-1"></kor-tag>
              }
            </b>
          </kor-table-cell>
          <kor-table-cell>{{
            user?.lastCampaignDate?.toDate() | date: 'MMM d, HH:mm'
          }}</kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Cadastre chaves de disparo</h1>
      <p class="leading-snug text-black/60">
        Para realizar disparos em massa, adicione uma chave de disparo para cada
        número do WhatsApp de seu time.
      </p>

      <a
        href="https://home.blu.direct/blog/visao-geral-disparos"
        target="_blank"
        class="no-underline"
      >
        <button class="secondary">Saiba mais</button>
      </a>
    </div>
  }
</div>

<router-outlet name="modal"></router-outlet>
