import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BluApiService } from '@app/services/blu-api.service';
import { ChannelsService } from '@app/services/channels.service';
import { SharedService } from '@app/services/shared.service';

@Component({
  selector: 'blu-activate-channel',
  templateUrl: './activate-channel.component.html',
  styleUrls: ['./activate-channel.component.scss'],
})
export class ActivateChannelComponent implements OnInit, OnDestroy {
  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public bluApi: BluApiService,
    private channelsService: ChannelsService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.bluApi.initializeChannelQR(id);
    this.bluApi.qr$.subscribe({
      error: () => this.sharedService.closeModal(this.route),
      complete: () => {
        this.sharedService.closeModal(this.route);
        this.channelsService.setChannelToConnected(id);
      },
    });
  }

  ngOnDestroy(): void {
    this.bluApi.closeQRConnection();
  }
}
