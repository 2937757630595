<!-- modal -->
<shared-modal [mobile]="sharedService.screenSize !== 'l'">
  <!-- header -->
  <h2 slot="header">Como foi sua experiência hoje?</h2>
  <!-- content -->
  <kor-textarea
    label="Comentário"
    rows="4"
    [(ngModel)]="feedbackService.comment"
    ngDefaultControl
  ></kor-textarea>
  <!-- footer -->
  <button
    (click)="sharedService.closeModal(route)"
    class="secondary"
    slot="footer"
  >
    Cancelar
  </button>
  <button
    (click)="feedbackService.submitFeedback(); sharedService.closeModal(route)"
    slot="footer"
  >
    Enviar
  </button>
</shared-modal>
