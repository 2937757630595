@if (userService.organization()) {
  @if (sharedService.screenSize !== 's' || menuDrawerVisible) {
    <blu-nav-bar (close)="menuDrawerVisible = false"></blu-nav-bar>
  }
  <section
    class="flex flex-col items-center w-full p-5 gap-5 overflow-hidden box-border"
  >
    <!-- splash -->
    <div class="bg-accent-950 absolute top-0 left-0 w-full h-80 -z-10"></div>

    <!-- header -->
    <blu-header theme="dark">
      @if (sharedService.screenSize === 's') {
        <kor-button
          color="secondary"
          icon="menu"
          (click)="
            menuDrawerVisible = true;
            analyticsService.logEvent('admin_header_menu_icon_click')
          "
        ></kor-button>
      }
    </blu-header>

    <router-outlet></router-outlet>
  </section>
}

<router-outlet name="modal"></router-outlet>
