import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../../services/shared.service';
import { UserService } from '../../../../../services/user.service';

import { ActivatedRoute } from '@angular/router';
import {
  FirestoreCollections,
  ITemplate,
} from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { AiService } from '@app/services/ai.service';
import { serverTimestamp } from '@angular/fire/firestore';

@Component({
  selector: 'blu-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class AddTemplateComponent implements OnInit {
  text: string | undefined;
  media: string[] = [];
  name: string | undefined;
  id: string | null = null;
  formattingText = false;

  constructor(
    public sharedService: SharedService,
    public userService: UserService,
    private firestore: SharedFirestoreService,
    public route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public AIService: AiService,
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.getTemplate(this.id);
    }
  }

  private async getTemplate(id: string): Promise<void> {
    const docData = await this.firestore.getDoc<ITemplate>(
      FirestoreCollections.Template,
      id,
    );
    if (!docData) return;
    this.text = docData.text;
    this.media = docData.media;
    this.name = docData.name;
    this.id = docData.uid;
  }

  setValue(key: any, tar: any): void {
    this[key as keyof this] = tar.value;
  }

  async saveTemplate(): Promise<void> {
    const org = this.userService.organization();
    if (!org) return;
    const docData = {
      name: this.name,
      text: this.text,
      media: this.media,
      organization: org.uid,
      campaignsSent: 0,
      createdAt: serverTimestamp(),
    };
    if (this.id) {
      await this.firestore.updateDoc(
        FirestoreCollections.Template,
        this.id,
        docData,
      );
      this.sharedService.closeModal(this.route);
    } else {
      await this.firestore.addDoc(FirestoreCollections.Template, docData);
      this.sharedService.closeModal(this.route);
    }
  }

  async deleteTemplate(): Promise<void> {
    if (!this.id) return;
    await this.firestore.deleteDoc(FirestoreCollections.Template, this.id);
    this.sharedService.closeModal(this.route);
  }

  copyTemplateUrlToClipboard(): void {
    const urlString = `https://blu.direct/template/${this.id}`;
    navigator.clipboard.writeText(urlString);
  }

  async formatText() {
    this.formattingText = true;
    if (!this.text) return;
    const formattedText = await this.AIService.formatText(this.text);
    this.text = formattedText;
    this.formattingText = false;
  }
}
