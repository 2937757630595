import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataItem } from '@swimlane/ngx-charts';
import { IStats } from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { ChartService } from '../../services/chart.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { take } from 'rxjs';

@Component({
  selector: 'blu-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  messages: any = {
    currentPeriod: { sum: 0 },
    previousPeriod: { sum: 0 },
  };
  campaigns: any = {
    currentPeriod: { sum: 0 },
    previousPeriod: { sum: 0 },
  };
  constructor(
    private router: Router,
    private userService: UserService,
    private chartService: ChartService,
    private sharedService: SharedService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    const user = this.userService.user();
    if (!user) {
      this.router.navigateByUrl('/sign-in');
      return;
    }
    const userId = user.uid;
    this.getData(userId, 'currentPeriod', { from: 30, to: 0 });
    this.getData(userId, 'previousPeriod', { from: 60, to: 30 });
  }

  private async getData(
    userId: string,
    period: 'currentPeriod' | 'previousPeriod',
    dates: { from: number; to: number },
  ): Promise<void> {
    // get array of stats
    const statsArray = await this.chartService.getStatsBetweenDates(
      `user/${userId}`,
      this.sharedService.getPastDate(dates.from),
      this.sharedService.getPastDate(dates.to),
    );
    const messagesArray: DataItem[] = [];
    const campaignsArray: DataItem[] = [];
    statsArray.forEach(async (statsDoc: IStats) => {
      if (!statsDoc.lastCampaignDate) return;
      const date = this.chartService.modelDate(statsDoc.lastCampaignDate);
      // build arrays of data items
      messagesArray.push({
        name: date,
        value: statsDoc.messagesSentCount || 0,
      });
      campaignsArray.push({
        name: date,
        value: statsDoc.campaignsCount || 0,
      });
    });
    // then set sum for each
    this.messages[period] = {
      sum: this.chartService.sumArrayValue(messagesArray),
    };
    this.campaigns[period] = {
      sum: this.chartService.sumArrayValue(campaignsArray),
    };
  }
}
