import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { BluApiService } from '../../../services/blu-api.service';

@Component({
  selector: 'blu-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserSignInComponent {
  constructor(
    public router: Router,
    public bluApi: BluApiService,
    private userService: UserService,
  ) {
    this.bluApi.initializeQR(() => this.router.navigateByUrl('/user/send'));
  }

  reloadPage() {
    window.location.reload();
  }
}
