import { Injectable } from '@angular/core';

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  updateDoc,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  QuerySnapshot,
  DocumentReference,
  setDoc,
  SetOptions,
} from '@angular/fire/firestore';
import { FirestoreCollections } from 'libs/shared/src/lib/interfaces';

type CollectionName = FirestoreCollections | string;

@Injectable({
  providedIn: 'root',
})
export class SharedFirestoreService {
  constructor(public afs: Firestore) {}

  async getDoc<T>(
    collectionName: CollectionName,
    docId: string,
  ): Promise<T | undefined> {
    const colRef = collection(this.afs, collectionName);
    const docRef = doc(colRef, docId);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as T;
  }

  async updateDoc<T extends Record<string, any>>(
    collectionName: CollectionName,
    docId: string,
    docData: T,
  ): Promise<void> {
    const colRef = collection(this.afs, collectionName);
    const docRef = doc(colRef, docId);
    await updateDoc(docRef, docData);
  }

  async addDoc<T extends Record<string, any>>(
    collectionName: CollectionName,
    docData: T,
    updateWithDocId = true,
  ): Promise<DocumentReference> {
    const colRef = collection(this.afs, collectionName);
    const docRef = await addDoc(colRef, docData);
    if (updateWithDocId) await updateDoc(docRef, { uid: docRef.id });
    return docRef;
  }

  async deleteDoc(
    collectionName: CollectionName,
    docId: string,
  ): Promise<void> {
    const colRef = collection(this.afs, collectionName);
    const docRef = doc(colRef, docId);
    await deleteDoc(docRef);
  }

  async deleteDocAndSubCollection(
    collectionName: CollectionName,
    subCollectionName: CollectionName,
    docId: string,
  ): Promise<void> {
    const docRef = doc(collection(this.afs, collectionName), docId);
    const subColQuery = query(collection(docRef, subCollectionName));

    const subColQuerySnap = await getDocs(subColQuery);
    const promises = subColQuerySnap.docs.map((docSnap) =>
      deleteDoc(docSnap.ref),
    );
    promises.push(deleteDoc(docRef));
    await Promise.all(promises);
  }

  getDocs<T>(
    collectionName: CollectionName,
    ...queryConstraints: QueryConstraint[]
  ): Promise<QuerySnapshot<T>> {
    const colRef = collection(this.afs, collectionName);
    const colQuery = query(colRef, ...queryConstraints);
    return getDocs(colQuery) as Promise<QuerySnapshot<T>>;
  }

  setDoc<T extends Record<string, any>>(
    collectionName: CollectionName,
    docId: string,
    docData: Partial<T>,
    options: SetOptions,
  ): Promise<void> {
    const colRef = collection(this.afs, collectionName);
    const docRef = doc(colRef, docId);
    return setDoc(docRef, docData, options);
  }

  doc<T>(collectionName: CollectionName, docId: string): DocumentReference<T> {
    return doc(this.afs, collectionName, docId) as DocumentReference<T>;
  }
}
