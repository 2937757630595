import { Component, ElementRef, OnInit } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { where } from '@firebase/firestore';
import { FirestoreCollections } from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';

type Announcement = {
  text?: string;
  endsAt: Timestamp;
  type?: 'error' | 'information';
};

@Component({
  selector: 'blu-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
  announcements?: Announcement[];

  constructor(
    private firestoreService: SharedFirestoreService,
    private elRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.getAnnouncements();
  }

  async getAnnouncements() {
    const now = new Date();
    // get announcements starting before now and ending after now
    const announcements = await this.firestoreService.getDocs<Announcement>(
      FirestoreCollections.Announcement,
      where('endsAt', '>', now),
    );
    if (!announcements || announcements.docs.length === 0) return;

    this.elRef.nativeElement.style.display = 'flex';
    this.announcements = announcements.docs.map((doc) => doc.data());
  }
}
