<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? 'auto' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <!-- header -->
  <header class="header">
    <h2>Editar template</h2>
    <kor-icon
      button
      icon="close"
      (click)="sharedService.closeModal(route)"
    ></kor-icon>
  </header>
  <!-- body -->
  <kor-input
    label="Nome"
    [value]="name"
    (input)="setValue('name', $event.target)"
  ></kor-input>
  <kor-textarea
    label="Texto"
    rows="12"
    [value]="text"
    (input)="setValue('text', $event.target)"
  ></kor-textarea>
  <!-- format with ai -->
  <p class="format-ai">
    Melhore sua mensagem usando Inteligência Artificial.
    @if (!formattingText) {
      <kor-button
        icon="auto_awesome"
        [disabled]="!text"
        (click)="
          formatText();
          analyticsService.logEvent('admin_template_format-message_use_click')
        "
      ></kor-button>
    } @else {
      <kor-spinner></kor-spinner>
    }
  </p>
  <!-- images -->
  <hr />
  <h2>Adicionar mídia</h2>
  <blu-media-input [(media)]="media" folderPath="templates"></blu-media-input>
  <!-- footer -->
  <div class="footer">
    @if (id) {
      <kor-button
        color="tertiary"
        style="margin-right: auto"
        label="Deletar"
        (click)="
          deleteTemplate();
          analyticsService.logEvent('admin_delete_template_click')
        "
      ></kor-button>
      <kor-button
        label="Copiar link"
        color="secondary"
        (click)="
          copyTemplateUrlToClipboard();
          analyticsService.logEvent('admin_copy_template_link_click')
        "
      ></kor-button>
    }
    <kor-button
      [disabled]="!name || !text || formattingText"
      label="Salvar"
      slot="footer"
      (click)="
        saveTemplate(); analyticsService.logEvent('admin_save_template_click')
      "
    ></kor-button>
  </div>
</div>
