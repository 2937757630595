@if (channel) {
  <shared-modal [mobile]="sharedService.screenSize !== 'l'">
    <!-- header -->
    <h2 slot="header">Selecionar Chatbot</h2>

    <!-- body -->
    <div class="flex flex-col gap-2">
      @for (flow of flowListService.flows; track flow) {
        <kor-checkbox
          [label]="flow.label"
          [active]="channel.activeFlow === flow.uid"
          (click)="
            $event.stopImmediatePropagation();
            selectFlow(flow.uid);
            analyticsService.logEvent('admin_select_flow_click')
          "
        >
        </kor-checkbox>
      }
    </div>

    <!-- footer -->
    <kor-button
      slot="footer"
      color="secondary"
      label="Cancelar"
      (click)="sharedService.closeModal(route)"
    >
    </kor-button>
    <kor-button
      slot="footer"
      label="Confirmar"
      [disabled]="!channel.name"
      (click)="
        save(); analyticsService.logEvent('admin_save_flow_selection_click')
      "
    >
    </kor-button>
  </shared-modal>
}
