import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, map, merge, timer } from 'rxjs';

@Pipe({
  name: 'loading',
})
export class LoadingPipe implements PipeTransform {
  errorMessage =
    '\n O carregamento está demorando mais que o de costume.\n Favor atualizar a página e tentar novamente.';

  constructor() {}

  transform(value: string, ...args: unknown[]) {
    // create an observable that triggers in after some time with the error message
    const slowLoading = timer(60 * 1000).pipe(
      map(() => value + this.errorMessage),
    );
    // merge the original value with the slow loading observable
    const message = merge(new BehaviorSubject(value), slowLoading);
    return message;
  }
}
