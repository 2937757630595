<shared-modal [mobile]="sharedService.screenSize !== 'l'">
  <h2 slot="header">Escolher template</h2>
  <p>Escolha um modelo de mensagem para usar em seu disparo</p>
  <div class="flex flex-col gap-3 py-1">
    @for (template of templates; track template) {
      <div
        class="card template cursor-pointer"
        [ngClass]="{ active: selectedTemplate === template }"
        (click)="
          selectedTemplate = template;
          analyticsService.logEvent('user_template_card_click')
        "
      >
        {{ template.name }}
      </div>
    }
  </div>
  <!-- footer -->
  <button
    slot="footer"
    class="secondary"
    (click)="
      sharedService.closeModal(route);
      analyticsService.logEvent('user_template_close_click')
    "
  >
    Cancelar
  </button>
  <button
    slot="footer"
    (click)="
      chooseTemplate(); analyticsService.logEvent('user_template_confirm_click')
    "
    [disabled]="!selectedTemplate"
  >
    Confirmar
  </button>
</shared-modal>
