import { Timestamp } from '@angular/fire/firestore';
import { SafeUrl } from '@angular/platform-browser';

export enum FirestoreCollections {
  Admin = 'admin',
  Campaign = 'campaign',
  Organization = 'organization',
  Products = 'products',
  Template = 'template',
  User = 'user',
  Announcement = 'announcement',
  Feedback = 'feedback',
  Channel = 'channel',
  Operator = 'operator',
  Flow = 'flow',
  Node = 'node',
  Category = 'category',
}

export enum DatabaseCollections {
  Sessions = 'sessions',
  Recipients = 'recipients',
  Campaigns = 'campaigns',
  Messages = 'messages',
  Protocols = 'protocols',
}

export interface User {
  last_session_date: Timestamp;
  numbers: string[];
}

export enum WSError {
  Forbidden = 'forbidden',
  TemplateForbidden = 'templateForbidden',
  Logout = 'logout',
  SendMessages = 'sendMessages',
  InitializationError = 'initializationError',
  Subscription = 'subscription',
  WrongNumber = 'wrongNumber',
}

export enum AdminLoginError {
  'auth/invalid-email' = 'O Email usado é inválido ou ainda não foi registrado.',
  'auth/user-not-found' = 'O Email digitado ainda não foi registrado.',
  'auth/too-many-requests' = 'Você realizou muitas tentativas de login. Por favor, tente novamente mais tarde.',
  'auth/wrong-password' = 'A senha digitada está incorreta.',
  'auth/weak-password' = 'A senha digitada é muito curta ou muito comum.',
  'auth/email-already-in-use' = 'O Email usado já foi registrado.',
  'no-email' = 'Digite um Email primeiro.',
  'operator-not-found' = 'Email não registrado para atendimento. Faça o registro no painel do administrador.',
}

export enum SubscriptionType {
  Individual = 'individual',
  Basic = 'basico',
  Plus = 'plus',
  Pro = 'pro',
}

// interfaces for Firestore collections
export interface IAdmin {
  email: string;
  name: string;
  stripeId: string;
  stripeLink: string;
  uid: string;
}

export interface ICampaign {
  recipients: IRecipient[];
  createdAt: Timestamp;
  media: string[];
  poll: { options: string[]; name: string | null };
  messagesSent: number;
  messagesTotal: number;
  messageDelay: number;
  intervalSettings?: {
    messageCount: number;
    interval: number;
  };
  organization: string;
  sentBy: string;
  status: string;
  text: string;
  viewOnce?: boolean;
  source: 'csv' | 'whatsapp' | 'template';
}

export interface IOrganization {
  admins: string[];
  userTags?: string[];
  name: string;
  email: string;
  phoneNumber: string;
  uid: string;
  subscription?: {
    plan: string;
    addons?: string[];
    status: string;
    current_period_start: Timestamp;
    current_period_end: Timestamp;
  } | null;
  createdAt?: Timestamp;
  isBetaTester?: boolean;
  lastGuestRecipientsExportDate?: Timestamp;
  onboarding?: IOnboarding;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
}

export enum OnboardingStepKey {
  createdUser = 'createdUser',
  createdCampaign = 'createdCampaign',
  createdFlow = 'createdFlow',
  createdChannel = 'createdChannel',
  createdOperator = 'createdOperator',
  usedTemplateLink = 'usedTemplateLink',
}

interface IOnboardingStep {
  completedAt: Timestamp | null;
  dismissedAt: Timestamp | null;
}

export type IOnboarding = Record<OnboardingStepKey, IOnboardingStep>;

export interface ITemplate {
  campaignsSent: number;
  createdAt: Timestamp;
  media: string[];
  poll: { options: string[]; name?: string };
  name: string;
  organization: string;
  text: string;
  uid: string;
}

export interface IUser {
  createdAt: Timestamp;
  deletedAt: Timestamp;
  lastCampaignDate: Timestamp;
  lastContactImportDate: Timestamp;
  name: string;
  numbers: string[];
  organization: string;
  uid: string;
  role?: 'admin' | 'staff';
}

// sometimes we add extra fields to some of the Firestore interfaces
export interface IBluUser extends IUser {
  image?: string;
  type?: 'user' | 'admin' | 'staff';
  tags?: string[];
}

export interface IBluAdmin extends IAdmin {
  image?: string;
  type?: 'user' | 'admin' | 'staff';
}

export interface IBluCampaign extends ICampaign {
  id?: string;
  userName?: string;
}

export interface IContact {
  id: string;
  createdAt: Date;
  deletedAt: Date;
  lastCampaignDate: Date;
  name: string;
  number: string;
  whatsappId: string;
  organization: string;
  createdBy: string;
  isGroup: boolean;
  fromCsv: boolean;

  // utility fields
  checked?: boolean;
}

export interface ICsvContact extends IContact {
  recipient: IRecipient;
}
export interface ICsvImportResponse {
  headers: string[];
  contacts: ICsvContact[];
}

export type IRecipient = {
  chatId: string;
  variables?: {
    [key: string]: string;
  };
};

export type IChannel = {
  createdAt: Date;
  deletedAt: Date | null;
  name?: string;
  numbers: string[];
  operators: string[];
  organization: string;
  uid?: string;
  activeFlow: string | null;
  startedWarmingUpAt?: Date | null;
};

export enum ChannelStatus {
  CONNECTED = 'CONNECTED',
  NOT_FOUND = 'NOT_FOUND',
  DISCONNECTED = 'DISCONNECTED',
}

export type IHydratedChannel = IChannel & {
  status?: ChannelStatus;
};

export type IWarmupChannel = IChannel & {
  status?: ChannelStatus;
  temperature?: 'quente' | 'morno' | 'frio' | null;
};

export type IOperator = {
  createdAt: Date;
  deletedAt: Date | null;
  name?: string;
  organization: string;
  uid: string;
  email?: string;
};

export type IStats = {
  lastCampaignDate?: Timestamp;
  messagesSentCount?: number;
  campaignsCount?: number;
  scope?: string;
  organization?: string;
  user?: string;
};

export interface AuthUser {
  displayName: string;
  uid: string;
  email: string;
  phoneNumber: string;
  token: string;
}

export interface MessageContent {
  text?: string;
  media: string[];
  viewOnce: boolean;
  poll: {
    name: string | null;
    options: string[];
  };
}

export interface IChat {
  id: string;
  name: string;
  notify?: string;
  latestMessage?: IMessage;
  lastMessageRecvTimestamp: number;
  // not being used
  // participant: any[];
  // unreadCount: number;
  // readOnly: boolean;
  // ephemeralExpiration: number;
  // pHash: string;
  // notSpam: boolean;
  // unreadMentionCount: number;
  // suspended: boolean;
  // isDefaultSubgroup: boolean;
  //
  bluLabel?: string;
}

interface IChatMessage {
  message: IChatMessageDetails;
}

interface IChatMessageDetails {
  key: IChatMessageKey;
  message: IMessageContent;
  messageTimestamp: string;
  participant: string;
  ephemeralOutOfSync: boolean;
  reactions?: IChatReaction[];
  status?: string;
  userReceipt?: IChatUserReceipt[];
}

interface IChatMessageKey {
  remoteJid: string;
  fromMe: boolean;
  id: string;
  participant?: string;
}

interface IChatReaction {
  key: IChatReactionKey;
  text: string;
  senderTimestampMs: string;
}

interface IChatReactionKey {
  remoteJid: string;
  fromMe: boolean;
  id: string;
  participant: string;
}

interface IChatUserReceipt {
  userJid: string;
  receiptTimestamp: string;
  readTimestamp: string;
  playedTimestamp: string;
}

interface IKey {
  remoteJid: string;
  fromMe: boolean;
  id: string;
}

interface IReceipt {
  userJid: string;
  receiptTimestamp: string;
  readTimestamp: string;
  playedTimestamp: string;
}

interface IImageMessage {
  url: string;
  mimetype: string;
  caption: string;
  fileSha256: string;
  fileLength: string;
  height: number;
  width: number;
  mediaKey: string;
  fileEncSha256: string;
  directPath: string;
  mediaKeyTimestamp: string;
  scansSidecar: string;
  scanLengths: number[];
  midQualityFileSha256: string;
  jpegThumbnail?: string;
}

interface IStickerMessage {
  url: string;
  fileSha256: string;
  fileEncSha256: string;
  mediaKey: string;
  mimetype: string;
  directPath: string;
  fileLength: string;
  mediaKeyTimestamp: string;
  isAnimated: boolean;
  stickerSentTs: string;
  isAvatar: boolean;
}

interface IMessageContent {
  conversation?: string;
  imageMessage?: IImageMessage;
  stickerMessage?: IStickerMessage;
  reactionMessage: any;
  extendedTextMessage?: {
    text: string;
    previewType: string;
    contextInfo: {
      stanzaId: string;
      participant: string;
      quotedMessage: {
        conversation: string;
      };
      entryPointConversionSource?: string;
      entryPointConversionApp?: string;
      entryPointConversionDelaySeconds?: number;
    };
    inviteLinkGroupTypeV2: string;
  };
  audioMessage?: any; // TODO: add type when needed
  videoMessage?: any; // TODO: add type when needed
  viewOnceMessageV2?: any; // TODO: add type when needed
  viewOnceMessageV2Extension?: any; // TODO: add type when needed
}

interface IMediaData {
  localPath: string;
}

export interface IMessage {
  messagesStubParameters: any[];
  labels: any[];
  userReceipt: IReceipt[];
  reactions: any[];
  pollUpdates: any[];
  key: IKey;
  message: IMessageContent;
  messageTimestamp: number;
  ephemeralOutOfSync?: boolean;
  status?: number;
  mediaData?: IMediaData;
  pushName?: string;
  participant?: string;
  // utility fields
  imageSource?: SafeUrl;
  videoSource?: SafeUrl;
  audioSource?: SafeUrl;
}

export type IRandomVariable = {
  name: string;
  values: string[];
};

export interface IFlow {
  uid: string;
  label: string;
  organization: string;
  startingNode?: string;
  timeoutDuration?: number | null;
}

export interface INode {
  uid: string;
  label: string;
  text?: string;
  media?: string[];
  responseOptions: IResponseOption[];
  coords?: {
    x: number;
    y: number;
  };
}

export interface IResponseOption {
  index: number;
  label: string;
  actionNode?: string | null;
  runScript?: string | null;
}

export interface IChatCategory {
  uid?: string;
  label?: string;
  organization: string;
  operators: string[];
}

export enum BluLabels {
  not_contacted = 'Não Atendido',
  in_negotiation = 'Em Atendimento',
  presentation_scheduled = 'Apresentação Agendada',
  qualified_to_buy = 'Qualificado para Comprar',
  closed_won = 'Negócio Ganho',
  closed_lost = 'Negócio Perdido',
}
