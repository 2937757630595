<section class="card min-h-full">
  <!-- header -->
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded"> support_agent </span>
    Atendentes ({{ operatorsService.operators.length }}/{{
      userService.getOperatorLimit()
    }})
    <button
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
      class="ml-auto tertiary !px-1 md:!px-3"
      [disabled]="
        operatorsService.operators.length >= userService.getOperatorLimit()
      "
    >
      <span class="material-symbols-rounded m-auto text-black/60"> add </span>
      @if (sharedService.screenSize !== 's') {
        Adicionar
      }
    </button>
  </h2>

  <!-- error -->
  @if (operatorsService.operators.length >= userService.getOperatorLimit()) {
    <p
      class="flex-col md:flex-row p-3 border border-solid rounded-md bg-red-400/10 flex items-center gap-3 border-red-400"
    >
      <b class="flex-1"
        >O limite de atendentes para seu plano ({{
          userService.getOperatorLimit()
        }}) foi atingido.</b
      >
      <a
        class="no-underline self-end w-full md:w-fit"
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+contratar+mais+atendentes"
      >
        <button class="w-full">Adquirir mais atendentes</button>
      </a>
    </p>
  }

  <!-- body -->
  @if (operatorsService.operators.length > 0) {
    <p class="text-balance text-black/60">
      Atendentes podem ser associados a canais para ter acesso às mensagens pelo
      <a href="https://chat.blu.direct/" class="font-bold text-accent-600"
        >painel de atendimento</a
      >
      utilizando o e-mail e senha cadastrados.
      <a
        href="https://home.blu.direct/blog/visao-geral-atendimento"
        target="_blank"
        class="font-bold text-accent-600"
        >Saiba mais</a
      >
    </p>
    <kor-table>
      <kor-table-row slot="header">
        <kor-table-cell head grid-cols="10">Nome</kor-table-cell>
        <kor-table-cell head grid-cols="10">Email</kor-table-cell>
        <kor-table-cell grid-cols="4"></kor-table-cell>
      </kor-table-row>
      @for (operator of operatorsService.operators; track operator) {
        <kor-table-row>
          <kor-table-cell grid-cols="10">
            <b>{{ operator.name }}</b>
          </kor-table-cell>
          <kor-table-cell grid-cols="10">{{ operator.email }} </kor-table-cell>
          <kor-table-cell grid-cols="4" alignment="right">
            <kor-button
              button
              label="Editar"
              icon="edit"
              color="secondary"
              [routerLink]="[{ outlets: { modal: ['add', operator.uid] } }]"
            ></kor-button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Cadastre seu primeiro atendente</h1>
      <p class="leading-snug text-black/60">
        Atendentes podem ser associados a canais para ter acesso às mensagens
        pelo
        <a href="https://chat.blu.direct/" class="font-bold text-accent-600"
          >painel de atendimento</a
        >.
      </p>

      <a
        href="https://home.blu.direct/blog/visao-geral-atendimento"
        target="_blank"
        class="no-underline"
      >
        <button class="secondary">Saiba mais</button>
      </a>
    </div>
  }
</section>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
