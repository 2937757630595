import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/shared.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'blu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  userMenuVisible = false;

  // listen to window scroll
  @HostListener('window:scroll', ['$event'])
  handleScroll(): void {
    const tar: HTMLElement = document.documentElement;
    if (tar.scrollTop + tar.clientHeight + 56 >= tar.scrollHeight) {
      this.sharedService.scrollBottom.emit();
    }
  }

  // when resizing, update screen size
  @HostListener('window:resize', ['$event'])
  handleResize(): void {
    this.sharedService.setScreenSize();
  }

  constructor(
    public router: Router,
    public userService: UserService,
    public sharedService: SharedService,
    public alertService: AlertService,
    // initialize auth service to listen to auth state change
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.sharedService.setScreenSize();
  }
}
