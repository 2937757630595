import { Injectable } from '@angular/core';
import { ICsvContact, IContact } from 'libs/shared/src/lib/interfaces';
import { SendService } from './send.service';
import { UserService } from './user.service';
import { BluApiService } from './blu-api.service';

export enum TimeFilters {
  FOUR_HOURS = 4,
  ONE_DAY = 24,
  SEVEN_DAYS = 24 * 7,
  FOURTEEN_DAYS = 24 * 14,
  ALL = 0,
}

export enum SourceFilters {
  WHATSAPP = 'whatsapp',
  CSV = 'csv',
  ALL = 'all',
}
export enum FilterTypes {
  TIME = 'time',
  SOURCE = 'source',
  DELETED = 'deleted',
  SEARCH = 'search',
  GROUP = 'group',
  INTERNATIONAL = 'international',
}

type DeletedTypes = boolean;

export enum GroupFilters {
  CONTACT = 'contact',
  GROUP = 'group',
  ALL = 'all',
}

type SearchTypes = string;

type InternationalTypes = boolean;

export type FilterValues =
  | TimeFilters
  | SourceFilters
  | DeletedTypes
  | SearchTypes
  | GroupFilters
  | InternationalTypes;
export type ActiveFilters = { [key in FilterTypes]: FilterValues };

type ContactList = (IContact | ICsvContact)[];

@Injectable({
  providedIn: 'root',
})
export class ContactListService {
  allContacts: ContactList = [];
  contactsForDisplay: ContactList = [];
  pageLimit = 20;
  isLoading = true;
  activeFilters: ActiveFilters = {
    [FilterTypes.TIME]: TimeFilters.ALL,
    [FilterTypes.SOURCE]: SourceFilters.WHATSAPP,
    [FilterTypes.DELETED]: false,
    [FilterTypes.SEARCH]: '',
    [FilterTypes.GROUP]: GroupFilters.CONTACT,
    [FilterTypes.INTERNATIONAL]: false,
  };

  constructor(
    private userService: UserService,
    public sendService: SendService,
    private bluApi: BluApiService,
  ) {}

  async getContacts() {
    const userId = this.userService.user()?.uid;
    if (!userId) return;
    const contacts = await this.bluApi.getContactsByUserId(userId);
    this.resetAllContacts();
    this.allContacts = contacts;
    this.applyFilters();
    this.isLoading = false;
  }

  applyFilters(shouldResetList = true): void {
    if (shouldResetList) this.resetContactList();

    let contacts = this.allContacts;
    for (const [filterType, filterValue] of Object.entries(
      this.activeFilters,
    )) {
      contacts = this.applyFilter(
        contacts,
        filterType as FilterTypes,
        filterValue,
      );
    }
    this.contactsForDisplay = contacts;
  }

  filterBySearch(contacts: ContactList, searchString: string): ContactList {
    if (!searchString) return contacts;
    return contacts.filter((contact) => {
      const nameMatches = contact.name
        ?.toLowerCase()
        .includes(searchString.toLowerCase());
      const numberMatches = contact.number.includes(searchString);
      return nameMatches || numberMatches;
    });
  }
  filterByTime(contacts: ContactList, hoursAmount: TimeFilters): ContactList {
    const dateFrom = this.getDateFrom(hoursAmount);
    return contacts.filter((contact) => {
      const lastCampaignDate = new Date(contact.lastCampaignDate);
      return (
        !dateFrom || !contact.lastCampaignDate || lastCampaignDate <= dateFrom
      );
    });
  }
  filterBySource(contacts: ContactList, source: SourceFilters): ContactList {
    if (source === SourceFilters.ALL) return contacts;
    return contacts.filter((contact) => {
      if (source === SourceFilters.CSV) {
        return contact.fromCsv;
      } else if (source === SourceFilters.WHATSAPP) {
        return !contact.fromCsv;
      } else throw new Error('Unreachable code'); // to make typescript happy
    });
  }
  filterByDeleted(contacts: ContactList, deleted: DeletedTypes): ContactList {
    return contacts.filter((contact) =>
      deleted ? contact.deletedAt : !contact.deletedAt,
    );
  }
  filterByInternational(
    contacts: ContactList,
    includeInternational: InternationalTypes,
  ): ContactList {
    if (includeInternational) return contacts;
    return contacts.filter((contact) => contact.number.startsWith('55'));
  }
  filterByGroup(contacts: ContactList, filter: GroupFilters): ContactList {
    if (filter === GroupFilters.ALL) return contacts;
    return contacts.filter((contact) => {
      return filter === GroupFilters.CONTACT
        ? !contact.isGroup
        : contact.isGroup;
    });
  }

  applyFilter(
    contacts: ContactList,
    filterType: FilterTypes,
    filterValue: FilterValues,
  ): ContactList {
    switch (filterType) {
      case FilterTypes.TIME:
        return this.filterByTime(contacts, filterValue as TimeFilters);
      case FilterTypes.SOURCE:
        return this.filterBySource(contacts, filterValue as SourceFilters);
      case FilterTypes.DELETED:
        return this.filterByDeleted(contacts, filterValue as DeletedTypes);
      case FilterTypes.SEARCH:
        return this.filterBySearch(contacts, filterValue as SearchTypes);
      case FilterTypes.GROUP:
        return this.filterByGroup(contacts, filterValue as GroupFilters);
      case FilterTypes.INTERNATIONAL:
        return this.filterByInternational(
          contacts,
          filterValue as InternationalTypes,
        );
    }
  }

  selectAll(): void {
    const limit = this.sendService.messageLimitNumber;
    const maxNumber = Math.min(this.contactsForDisplay.length, limit);
    this.checkAllContacts(false);
    // if max number of contacts are selected
    if (this.sendService.recipients.length >= maxNumber) {
      // uncheck all and reset recipient list and source
      this.sendService.setRecipients([]);
      this.sendService.recipientSource = SourceFilters.WHATSAPP;
    } else {
      // otherwise, check first X contacts
      this.checkAllContacts(true);
      const slicedContacts = this.contactsForDisplay.slice(0, maxNumber);
      const contactNumbers = slicedContacts.map((contact) => {
        return { chatId: contact.whatsappId };
      });
      this.sendService.setRecipients(contactNumbers);
      // set source to be whatsapp
      this.sendService.recipientSource = SourceFilters.WHATSAPP;
    }
  }

  checkAllContacts(checked: boolean) {
    const slicedContacts = checked
      ? this.contactsForDisplay.slice(0, this.sendService.messageLimitNumber)
      : this.contactsForDisplay;
    slicedContacts.forEach((contact) => {
      contact.checked = checked;
    });
  }

  resetContactList() {
    this.contactsForDisplay = [];
    this.sendService.setRecipients([]);
    this.allContacts.forEach((contact) => (contact.checked = false));
  }

  resetAllContacts() {
    this.isLoading = true;
    this.contactsForDisplay = [];
    this.allContacts = [];
  }

  getDateFrom(hours: number): Date | undefined {
    if (!hours) return;
    const dateFrom = new Date();
    dateFrom.setHours(dateFrom.getHours() - hours);
    return dateFrom;
  }

  setFilter(filterType: FilterTypes, filterValue: FilterValues): void {
    this.activeFilters[filterType] = filterValue;
  }
  setTimeFilter(hoursAmount: number): void {
    this.setFilter(FilterTypes.TIME, hoursAmount);
    this.applyFilters();
  }
  setSourceFilter(source: SourceFilters): void {
    this.setFilter(FilterTypes.SOURCE, source);
    this.applyFilters();
  }
  setDeletedFilter(isDeleted: boolean): void {
    this.setFilter(FilterTypes.DELETED, isDeleted);
    this.applyFilters();
  }
  setSearchFilter(searchString: string): void {
    this.setFilter(FilterTypes.SEARCH, searchString);
    this.applyFilters(false);
  }
  setGroupFilter(filterType: GroupFilters): void {
    this.setFilter(FilterTypes.GROUP, filterType);
    this.applyFilters();
  }
  setInternationalFilter(isInternational: boolean): void {
    this.setFilter(FilterTypes.INTERNATIONAL, isInternational);
    this.applyFilters();
  }

  async updateCheckedContacts(
    callable: (user: string, contacts: string[]) => Promise<void>,
  ): Promise<void> {
    const user = this.userService.user();
    if (!user?.uid) return;
    const selectedContacts = this.contactsForDisplay.filter(
      (contact) => contact.checked,
    );
    const contacts = selectedContacts.map((contact) => contact.whatsappId);
    if (!contacts.length) return;
    await callable(user.uid, contacts);

    this.getContacts();
    this.sendService.setRecipients([]);
  }

  async deleteContacts(): Promise<void> {
    this.updateCheckedContacts((user, contacts) =>
      this.bluApi.bulkDeleteContacts(user, contacts),
    );
  }

  async restoreContacts(): Promise<void> {
    this.updateCheckedContacts((user, contacts) =>
      this.bluApi.bulkUndeleteContacts(user, contacts),
    );
  }
}
