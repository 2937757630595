<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? '400px' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <!-- header -->
  <h2 class="header">
    Meus disparos
    <kor-icon
      button
      icon="cancel"
      [routerLink]="['/user', { outlets: { modal: null } }]"
      (click)="analyticsService.logEvent('user_history_close_click')"
    ></kor-icon>
  </h2>
  <kor-table readonly>
    @for (campaign of campaigns; track campaign) {
      <kor-table-row>
        <kor-table-cell>
          <kor-icon
            [icon]="sharedService.getCampaignStatusIcon(campaign.status).icon"
            [color]="sharedService.getCampaignStatusIcon(campaign.status).color"
          ></kor-icon>
        </kor-table-cell>
        <kor-table-cell>
          <b>
            {{ campaign.messagesSent }} de {{ campaign.messagesTotal }}
            mensagens enviadas
          </b>
        </kor-table-cell>
        <kor-table-cell>
          <p>
            {{ campaign.createdAt.toDate() | date: 'MMM dd H:mm' }}
            <kor-button
              color="tertiary"
              icon="content_copy"
              (click)="
                copyCampaign(campaign);
                analyticsService.logEvent('user_history_copy-campaign_click')
              "
            ></kor-button></p
        ></kor-table-cell>
      </kor-table-row>
    }
  </kor-table>
</div>
