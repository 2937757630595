<h1 class="flex items-center gap-1">
  <img
    class="cursor-pointer"
    src="assets/favicon.png"
    alt="The logo of blü"
    height="48"
    width="48"
    [routerLink]="'/sign-in'"
    (click)="analyticsService.logEvent('header_logo_click')"
  />blü
</h1>
@if (userService.organization(); as org) {
  <p class="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
    {{ org.name }}
  </p>
}
<!-- sub tag -->
@if (
  userService.user()?.type === 'admin' && !userService.subscriptionIsActive()
) {
  <!-- TODO: create modal with 3 links -->
  <kor-button
    label="Ativar"
    (click)="
      openPlansModal(); analyticsService.logEvent('header_subscribe_click')
    "
  ></kor-button>
}

<!-- avatar -->
@if (userService.user(); as user) {
  <kor-avatar
    class="ml-auto cursor-pointer"
    [image]="user.image"
    [label]="sharedService.screenSize === 'l' ? user.name : undefined"
    [info]="sharedService.screenSize === 'l' ? userService.number : undefined"
    (click)="
      userMenuVisible = true; analyticsService.logEvent('header_avatar_click')
    "
  ></kor-avatar>
}

<ng-content></ng-content>

<!-- user menu -->
<kor-drawer
  [visible]="userMenuVisible"
  position="right"
  (visible-changed)="closeUserMenu($event.target)"
  theme="light"
>
  @if (userService.user(); as user) {
    <kor-avatar
      slot="header"
      [image]="user.image"
      [label]="user.name"
      [info]="userService.number"
      (click)="analyticsService.logEvent('user-menu_avatar_click')"
    ></kor-avatar>
    <!-- sub tag -->
    @if (userService.subscriptionIsActive()) {
      <a
        class="w-fit capitalize py-0.5 px-2 rounded-md border border-solid no-underline text-black/90 cursor-pointer transition-all"
        target="_blank"
        [ngClass]="{
          'bg-emerald-500/20 hover:bg-emerald-500/30  border-emerald-500':
            userService.organization()?.subscription?.plan === 'individual',
          'bg-orange-500/20 hover:bg-orange-500/30  border-orange-500':
            userService.organization()?.subscription?.plan === 'plus',
          'bg-violet-500/20 hover:bg-violet-500/30  border-violet-500':
            userService.organization()?.subscription?.plan === 'basico',
          'bg-pink-500/20 hover:bg-pink-500/30  border-pink-500':
            userService.organization()?.subscription?.plan === 'pro'
        }"
        (click)="
          createDashboardLink();
          analyticsService.logEvent('header_subscription-tag_click')
        "
        >{{ userService.organization()?.subscription?.plan }}</a
      >
    }
  }
  @if (userService.user()?.type !== 'admin') {
    <kor-menu-item
      label="Meus disparos"
      icon="send"
      [routerLink]="['/user', { outlets: { modal: ['history'] } }]"
      (click)="
        userMenuVisible = false;
        analyticsService.logEvent('user-menu_my-campaings_click')
      "
    ></kor-menu-item>
  }
  <hr />
  <kor-menu-item
    label="Sair"
    (click)="
      userMenuVisible = false;
      sharedAuthService.signOut();
      analyticsService.logEvent('user-menu_sign-out_click')
    "
  ></kor-menu-item>
</kor-drawer>
