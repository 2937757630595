import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  FirestoreCollections,
  ICampaign,
} from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SharedService } from '@app/services/shared.service';

@Component({
  selector: 'blu-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class CampaignComponent implements OnInit {
  campaign?: ICampaign;

  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService,
    private firestore: SharedFirestoreService,
  ) {}

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.campaign = await this.firestore.getDoc(
      FirestoreCollections.Campaign,
      id,
    );
  }
}
