<h2 class="p-5 flex gap-3 h-8 items-center">
  <kor-icon button icon="arrow_back" routerLink="../"></kor-icon>
  {{ flowService.flow?.label }}
  <kor-button
    class="ml-auto"
    icon="auto_fix_high"
    label="Alinhar"
    (click)="alignFlow()"
  ></kor-button>
  <kor-checkbox
    label="Reiniciar fluxo após 15 min."
    [active]="flowService.flow?.timeoutDuration"
    (active-changed)="setTimeoutDuration($event)"
  ></kor-checkbox>
</h2>

<div
  class="canvas"
  (dragover)="onFlowDragOver($event)"
  (drop)="onFlowDrop($event)"
  #flow
>
  @for (node of flowService.nodes; track node) {
    <blu-flow-node
      class="z-10"
      [node]="node"
      [style.left]="'calc(var(--grid-size) * ' + node.coords?.x + ')'"
      [style.top]="'calc(var(--grid-size) * ' + node.coords?.y + ')'"
      [ngClass]="{
        outline: flowService.draggedOverNodeId === node.uid,
        'sm:transition-none': !isAutoAligning
      }"
      id="n_{{ node.uid }}"
      (optionDragStart)="onOptionDragStart($event)"
      (optionDragEnd)="onOptionDragEnd($event)"
      (dragenter)="onNodeDragEnter($event)"
      (dragleave)="onNodeDragLeave($event)"
      (dragover)="onNodeDragOver($event)"
      (drop)="onNodeDrop($event)"
    ></blu-flow-node>
  }
  @for (node of flowService.nodes; track node) {
    <ng-container>
      @for (option of node.responseOptions; track option) {
        @if (option.actionNode) {
          <svg>
            <path
              attr.data-node-id="{{ node.uid }}"
              attr.data-action-node-id="{{ option.actionNode }}"
              attr.data-option-id="{{ option.index }}"
            />
          </svg>
        }
      }
    </ng-container>
  }
</div>
