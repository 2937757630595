<!-- trigger template if campaign has not been created -->
@if (sendService.campaign.uid) {
  @if (sendService.progress < 100) {
    <!-- running -->
    @if (sendService.progress > 0) {
      <h1>
        Enviando mensagens ({{ sendService.campaign.messagesSent }} /
        {{ sendService.recipients.length }})
      </h1>
      <kor-progress-bar [value]="sendService.progress"></kor-progress-bar>
      <p>
        Tempo restante:
        {{ estimativeTime.hours }} hr{{
          estimativeTime.hours !== 1 ? 's' : ''
        }}
        {{ estimativeTime.minutes }} min{{
          estimativeTime.minutes !== 1 ? 's' : ''
        }}
        {{ estimativeTime.seconds }} seg
      </p>
      <p class="text-black/40 text-balance">
        O disparo continuará em andamento mesmo se esta aba for fechada.
      </p>
    } @else {
      <kor-spinner
        [label]="'Preparando disparo' | loading | async"
      ></kor-spinner>
    }
  } @else {
    <!-- success -->
    <h1>Disparo concluído!</h1>
    <kor-progress-bar
      value="100"
      color="rgb(var(--functional-green))"
    ></kor-progress-bar>
    <p>{{ sendService.campaign.messagesSent }} mensagens enviadas</p>
    @if (sendService.progress) {
      <hr class="w-full border-black/5" />
      <blu-feedback></blu-feedback>
    }
    <br />
    <button
      (click)="
        reloadPage(); analyticsService.logEvent('user_trigger_complete_click')
      "
    >
      Completar
    </button>
  }
} @else {
  <!-- trigger if campaign has not yet been created -->
  <div class="flex flex-col gap-3">
    <h1>Iniciar disparo</h1>
    <div class="flex gap-4">
      <p class="flex items-center gap-1 text-black/60">
        <span class="material-symbols-rounded"> schedule </span>
        {{ estimativeTime.hours }}h {{ estimativeTime.minutes }}m
        {{ estimativeTime.seconds }}s
      </p>
      <p class="flex items-center gap-1 text-black/60">
        <span class="material-symbols-rounded"> group </span>
        {{ sendService.recipients.length }}
        contatos
      </p>
    </div>
  </div>

  <div class="flex flex-col w-full">
    @for (preset of settingsPresets; track preset) {
      <div
        class="card button !gap-2 w-full text-start !px-4 !py-3 !rounded-none"
        [ngClass]="{
          'active z-[1]': selectedPreset === preset.name,
          '!rounded-t-lg': $first
        }"
        (click)="applyPreset(preset)"
      >
        <div class="title flex gap-3 items-center">
          <img
            src="assets/icons/{{ preset.icon }}.png"
            height="32"
            width="32"
          />
          <h2 class="flex-1">{{ preset.name }}</h2>
          <p class="text-black/60 ml-auto text-xs">
            ({{ preset.description }})
          </p>
        </div>
        @if (selectedPreset === preset.name) {
          <ul class="m-0">
            <li>{{ preset.delay }} segundos entre mensagens.</li>
            <li>
              {{ preset.intervalSettings.interval }} segundos a cada
              {{ preset.intervalSettings.messageCount }} mensagens.
            </li>
          </ul>
        }
      </div>
    }
    <div
      class="card button !gap-2 w-full text-start !px-4 !py-3 !rounded-none !rounded-b-lg"
      (click)="selectedPreset = undefined"
      [ngClass]="{
        'active z-[1]': !selectedPreset
      }"
    >
      <div class="title flex gap-3 items-center">
        <img src="assets/icons/custom.png" height="32" />
        <h2>Personalizado</h2>
      </div>

      @if (!selectedPreset) {
        <kor-input
          type="number"
          label="Intervalo entre mensagens (segundos)"
          [value]="delay"
          step="1"
          min="0"
          [max]="userService.organization()?.isBetaTester ? 900 : 60"
          no-clear
          (value-changed)="
            delay = getElementValue($event); this.setEstimativeTime()
          "
        ></kor-input>
        <kor-input
          type="number"
          label="Pausa (segundos)"
          [value]="intervalSettings.interval"
          step="10"
          min="0"
          [max]="userService.organization()?.isBetaTester ? 1600 : 240"
          no-clear
          (value-changed)="
            intervalSettings.interval = getElementValue($event);
            this.setEstimativeTime()
          "
        ></kor-input>
        <kor-input
          type="number"
          label="A cada (mensagens)"
          [value]="intervalSettings.messageCount"
          step="10"
          min="0"
          max="1000"
          no-clear
          (value-changed)="
            intervalSettings.messageCount = getElementValue($event);
            this.setEstimativeTime()
          "
        ></kor-input>
      }
    </div>
  </div>

  <button
    (click)="send(); analyticsService.logEvent('user_trigger_send_click')"
  >
    Iniciar disparo
  </button>
}
