<div class="card">
  <!-- header -->
  <h2>
    <kor-icon icon="label" class="font-normal"></kor-icon>
    Categorias
    <kor-button
      class="ml-auto"
      color="tertiary"
      icon="add"
      [label]="sharedService.screenSize !== 's' ? 'Adicionar' : null"
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
    ></kor-button>
  </h2>

  <!-- body -->
  <kor-table columns="3fr 1fr">
    <kor-table-row slot="header">
      <kor-table-cell head>Nome</kor-table-cell>
      <kor-table-cell head>Atendentes ativos</kor-table-cell>
    </kor-table-row>
    @for (category of categoriesService.categories; track category) {
      <kor-table-row
        [routerLink]="[{ outlets: { modal: ['add', category.uid] } }]"
      >
        <kor-table-cell>
          <b>{{ category.label }}</b>
        </kor-table-cell>
        <kor-table-cell> {{ category.operators.length }} </kor-table-cell>
      </kor-table-row>
    }
  </kor-table>
</div>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
