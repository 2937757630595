import { Component } from '@angular/core';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';

@Component({
  selector: 'blu-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  constructor(public analyticsService: AnalyticsService) {}
}
