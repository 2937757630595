import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { ChannelsService } from '@app/services/channels.service';

@Component({
  selector: 'blu-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
})
export class ChannelsComponent implements OnInit {
  constructor(
    private router: Router,
    public userService: UserService,
    public sharedService: SharedService,
    public channelsService: ChannelsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const org = this.userService.organization();
    if (!org) {
      this.router.navigate(['/sign-in/admin']);
      return;
    }
    await this.channelsService.getChannels(org.uid);
  }
}
