import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { DatabaseCollections } from '../interfaces';
import { SharedAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SharedBluApiService {
  isDev = false;

  constructor(
    private http: HttpClient,
    private sharedAuthService: SharedAuthService,
  ) {
    this.checkDev();
  }

  async buildHeaders() {
    const token = await this.sharedAuthService.getToken();
    return { authorization: token };
  }

  checkDev() {
    const url = new URL(window.location.href);
    this.isDev = url.searchParams.has('dev');
  }

  private get baseUrl() {
    if (this.isDev) {
      return environment.devBluApiUrl;
    }
    return environment.bluApiUrl;
  }

  buildUrl(
    collectionName: DatabaseCollections,
    docId?: string,
    resourceName?: string,
  ): string {
    let url = `${this.baseUrl}/${collectionName}`;
    if (docId) url += `/${docId}`;
    if (resourceName) url += `/${resourceName}`;
    return url;
  }

  async downloadMessageMedia(channel: string, body: { [key: string]: any }) {
    const url =
      this.buildUrl(DatabaseCollections.Messages, 'download') +
      `?channel=${channel}&s=${channel}`;
    const headers = await this.buildHeaders();
    return firstValueFrom(
      this.http.post(url, body, { headers, responseType: 'blob' }),
    );
  }

  async getChannelStatus(channelId: string) {
    const url =
      this.buildUrl(DatabaseCollections.Sessions, 'status') + `?s=${channelId}`;
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.get(url, { headers }));
  }

  async updateProtocol(
    channelId: string,
    chatId: string,
    status: 'pending' | 'active' | 'closed',
  ) {
    const url = `${this.buildUrl(
      DatabaseCollections.Protocols,
    )}?channelId=${channelId}&chatId=${chatId}`;
    const headers = await this.buildHeaders();
    return firstValueFrom(this.http.patch(url, { status }, { headers }));
  }
}
