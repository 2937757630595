<section class="card min-h-full">
  <!-- header -->
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded"> account_tree </span>
    Chatbots
    <button
      class="ml-auto tertiary !px-1 md:!px-3"
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
    >
      <span class="material-symbols-rounded m-auto text-black/60"> add </span>
      @if (sharedService.screenSize !== 's') {
        Adicionar
      }
    </button>
  </h2>

  <!-- body -->
  @if (flowsService.flows.length > 0) {
    <p class="text-balance text-black/60">
      Crie chatbots e os conecte a canais para realizar respostas automáticas.
      Um chatbot pode ser conectado a um ou mais canais.
      <a
        href="https://home.blu.direct/blog/visao-geral-chatbot"
        target="_blank"
        class="font-bold text-accent-600"
        >Saiba mais</a
      >
    </p>
    <kor-table columns="1fr auto auto">
      <kor-table-row slot="header">
        <kor-table-cell head>Nome</kor-table-cell>
        <kor-table-cell head></kor-table-cell>
        <kor-table-cell head></kor-table-cell>
      </kor-table-row>
      @for (flow of flowsService.flows; track flow) {
        <kor-table-row>
          <kor-table-cell>
            <b>{{ flow.label }}</b>
          </kor-table-cell>
          <kor-table-cell>
            <kor-button
              button
              color="secondary"
              icon="account_tree"
              label="Modificar fluxo"
              (click)="$event.stopPropagation()"
              [routerLink]="flow.uid"
            ></kor-button>
          </kor-table-cell>
          <kor-table-cell>
            <kor-button
              button
              color="secondary"
              icon="edit"
              label="Editar"
              (click)="$event.stopPropagation()"
              [routerLink]="[{ outlets: { modal: ['add', flow.uid] } }]"
            ></kor-button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Crie seu primeiro chatbot</h1>
      <p class="leading-snug text-black/60">
        Crie chatbots e os conecte a canais para realizar respostas automáticas.
        Um chatbot pode ser conectado a um ou mais canais.
      </p>

      <a
        href="https://home.blu.direct/blog/visao-geral-chatbot"
        target="_blank"
        class="no-underline"
      >
        <button class="secondary">Saiba mais</button>
      </a>
    </div>
  }
</section>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
