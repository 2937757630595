<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? 'auto' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <!-- header -->
  <h2>Importar lista de contatos</h2>
  <br /><br />
  <!-- body -->
  <div class="body">
    <li>Crie uma planilha como ilustrado na imagem abaixo <b></b></li>
    <li>
      Os <b>números de telefone</b> devem estar sempre na <b>primeira coluna</b>
    </li>
    <li>
      Colunas adicionais podem ser criadas para usar como
      <b>variáveis de mensagem</b>. Adicione um nome no cabeçalho de cada
      coluna.
    </li>
    <li>
      Salve sua planilha no formato <b>.csv</b> separado por vírgulas (excel)
    </li>

    <li>
      Evite enviar mensagens para mais de 200 contatos por lista para
      <b style="color: rgb(var(--functional-red))"
        >reduzir o risco de banimento</b
      >.
    </li>
    <img class="example-image" src="assets/images/exemplo-csv.jpg" />
  </div>
  <br /><br />
  <b style="color: rgb(var(--functional-red))">{{
    importErrorMessage | async
  }}</b>

  <div class="footer">
    <kor-button
      label="Voltar"
      color="secondary"
      slot="footer"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('user_recipients_upload_cancel_click')
      "
    ></kor-button>
    <div style="min-width: 112px">
      @if (!isLoading) {
        <kor-button
          label="Escolher lista"
          slot="footer"
          (click)="
            csvImportInput.click();
            analyticsService.logEvent('user_recipients_upload_choose_click')
          "
        ></kor-button>
      } @else {
        <kor-spinner></kor-spinner>
      }
    </div>
  </div>
</div>

<!-- csv import input -->
<input
  type="file"
  accept=".csv"
  placeholder="Search"
  (change)="handleCsvImport($event)"
  hidden
  #csvImportInput
/>
