import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/services/shared.service';
import { FlowListService } from '../../../../services/flow-list.service';

@Component({
  selector: 'flow-flows',
  templateUrl: './flows.component.html',
  styleUrls: ['./flows.component.scss'],
})
export class FlowsComponent implements OnInit {
  constructor(
    public flowsService: FlowListService,
    public sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    if (!this.flowsService.flows.length) this.flowsService.getFlows();
  }
}
