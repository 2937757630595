import { Component, inject } from '@angular/core';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import {
  AuthUser,
  FirestoreCollections,
  IOrganization,
} from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { AuthService } from '@app/services/auth.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { UserService } from '@app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'blu-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  loading = false;
  private fb = inject(FormBuilder);
  registrationForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    orgName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
  });

  constructor(
    public userService: UserService,
    private firestore: SharedFirestoreService,
    public authService: AuthService,
    public analyticsService: AnalyticsService,
  ) {}

  async onSubmit(): Promise<void> {
    this.loading = true;
    if (!this.registrationForm.valid) {
      this.loading = false;
      return;
    }
    const authUser = await this.authService.register(
      this.registrationForm.value.email,
      this.registrationForm.value.password,
      this.registrationForm.value.orgName,
      this.registrationForm.value.phoneNumber,
    );
    if (!authUser) {
      this.loading = false;
      return;
    }
    // the order here is important, first create org
    await this.createOrg(authUser.uid);
    await this.createAdmin(authUser);
    this.loading = false;
    this.analyticsService.logEvent('sign-in_admin_register_click');
  }

  private async createOrg(userId: string): Promise<void> {
    await this.firestore.addDoc<Omit<IOrganization, 'uid'>>(
      FirestoreCollections.Organization,
      {
        name: this.registrationForm.value.orgName,
        email: this.registrationForm.value.email,
        phoneNumber: this.registrationForm.value.phoneNumber,
        subscription: null,
        admins: [userId],
        createdAt: serverTimestamp() as Timestamp,
        onboarding: {
          createdUser: { completedAt: null, dismissedAt: null },
          createdCampaign: { completedAt: null, dismissedAt: null },
          createdFlow: { completedAt: null, dismissedAt: null },
          createdChannel: { completedAt: null, dismissedAt: null },
          createdOperator: { completedAt: null, dismissedAt: null },
          usedTemplateLink: { completedAt: null, dismissedAt: null },
        },
      },
    );
  }

  private async createAdmin(user: AuthUser): Promise<void> {
    this.firestore.setDoc(
      FirestoreCollections.Admin,
      user.uid,
      {
        name: user.displayName,
        email: user.email,
        uid: user.uid,
        createdAt: serverTimestamp(),
        phoneNumber: user.phoneNumber,
      },
      { merge: true },
    );
  }
}
