<div class="card">
  <!-- content -->
  <div class="grid">
    <!-- left menu -->
    <div class="stepper">
      <blu-stepper-item
        label="Conteúdo"
        icon="assets/icons/content.png"
        [routerLink]="'content'"
        [attr.active]="router.url === '/user/send/content'"
        (click)="analyticsService.logEvent('user_content_step_click')"
      ></blu-stepper-item>
      <kor-icon icon="chevron_right" color="var(--text-3)"></kor-icon>
      <blu-stepper-item
        label="Contatos"
        icon="assets/icons/contacts.png"
        [routerLink]="'recipients'"
        [attr.active]="router.url === '/user/send/recipients'"
        (click)="analyticsService.logEvent('user_recipients_step_click')"
      ></blu-stepper-item>
      <kor-icon icon="chevron_right" color="var(--text-3)"></kor-icon>
      <blu-stepper-item
        label="Envio"
        icon="assets/icons/send.png"
        [routerLink]="'trigger'"
        [attr.active]="router.url === '/user/send/trigger'"
        [attr.disabled]="sendService.recipients.length < 1"
        (click)="analyticsService.logEvent('user_trigger_step_click')"
      ></blu-stepper-item>
    </div>
    <!-- center content -->
    <router-outlet></router-outlet>
  </div>
</div>

<!-- modal -->
<router-outlet name="history-modal"></router-outlet>
