<shared-modal [mobile]="sharedService.screenSize !== 'l'">
  <!-- header -->
  <h2 slot="header">Editar atendente</h2>

  <kor-input
    label="Nome"
    [value]="credentials.displayName || operator?.name"
    (input)="setCredentials('displayName', $event.target)"
    [attr.readonly]="operator"
  ></kor-input>
  <kor-input
    label="Email"
    [value]="credentials.email || operator?.email"
    (input)="setCredentials('email', $event.target)"
    [attr.readonly]="operator"
  ></kor-input>
  <kor-input
    label="Senha"
    type="password"
    [value]="credentials.password"
    (input)="setCredentials('password', $event.target)"
    [attr.readonly]="operator"
  ></kor-input>
  <b class="font-bold min-h-[2em] text-red-600">{{
    authService.loginError | async
  }}</b>
  <!-- footer -->
  @if (operator?.uid) {
    <kor-button
      class="mr-auto"
      slot="footer"
      color="tertiary"
      label="Deletar"
      (click)="
        delete(); analyticsService.logEvent('admin_delete_operator_click')
      "
    ></kor-button>
  }
  <kor-button
    slot="footer"
    color="secondary"
    label="Cancelar"
    (click)="
      sharedService.closeModal(route);
      analyticsService.logEvent('admin_close_operator_modal')
    "
  >
  </kor-button>
  <kor-button
    slot="footer"
    label="Confirmar"
    [disabled]="
      !credentials.displayName ||
      !credentials.email ||
      !credentials.password ||
      operator
    "
    (click)="save(); analyticsService.logEvent('admin_save_operator_click')"
  >
  </kor-button>
</shared-modal>
