import { Component, effect } from '@angular/core';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { FirestoreCollections, IBluUser } from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'blu-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {
  users: IBluUser[] = [];

  constructor(
    public userService: UserService,
    public analyticsService: AnalyticsService,
    public sharedService: SharedService,
    private firestore: SharedFirestoreService,
  ) {
    effect(() => {
      const org = this.userService.organization();
      if (!org) return;
      this.getUsers(org.uid);
    });
  }

  private async getUsers(orgId: string): Promise<void> {
    const usersQuery = query(
      collection(this.firestore.afs, FirestoreCollections.User),
      where('organization', '==', orgId),
      where('deletedAt', '==', null),
      orderBy('lastCampaignDate', 'desc'),
    );
    onSnapshot(usersQuery, (usersQuerySnap) => {
      this.users = [];
      usersQuerySnap.forEach((userDocSnap) =>
        this.users.push(userDocSnap.data() as IBluUser),
      );
      // group similar tags close to each other
      this.users.sort((a, b) => {
        if (!a.tags) return -1;
        if (!b.tags) return 1;
        if (a.tags < b.tags) return -1;
        if (a.tags > b.tags) return 1;
        return 0;
      });
      return;
    });
  }

  // get user limit based on subscription
  // TODO: create plans collection on firebase and fetch limit from there
  getUserLimit(): number {
    const subs = this.userService.organization()?.subscription;
    let limit: number;
    switch (subs?.plan) {
      case 'individual':
        limit = 2;
        break;
      case 'basico':
        limit = 10;
        break;
      case 'plus':
        limit = 25;
        break;
      case 'pro':
        limit = 40;
        break;
      default:
        limit = 0;
    }
    // add users based on addons
    const addonCount =
      subs?.addons?.filter((addon) => addon === '10_users').length || 0;

    return limit + 10 * addonCount;
  }

  deleteTag(tag: string) {
    const org = this.userService.organization();
    if (!org) return;
    this.userService.organization.set({
      ...org,
      userTags: org.userTags?.filter((t) => t !== tag),
    });
    this.firestore.updateDoc(FirestoreCollections.Organization, org.uid, {
      userTags: org.userTags?.filter((t) => t !== tag),
    });
    // remove tag from all users
    for (const user of this.users) {
      if (!user.tags || !user.tags.includes(tag)) continue;
      user.tags = user.tags?.filter((t) => t !== tag);
      this.firestore.updateDoc(FirestoreCollections.User, user.uid, {
        tags: user.tags,
      });
    }
  }
}
