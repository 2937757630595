<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? '480px' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '720px' : '100%'"
>
  <!-- header -->
  <h2 class="header text-xl">Escolha seu plano para continuar</h2>

  @if (!userService.organization()?.subscription) {
    <p class="text-sm">
      Todos os planos incluem <b>teste grátis</b> de 3 dias e
      <b>reembolso garantido</b> em até 7 dias da contratação.
    </p>
  }
  <!-- grid -->
  @if (checkoutLoading) {
    <kor-spinner class="m-auto"></kor-spinner>
  } @else {
    <div
      class="w-full flex flex-col md:flex-row gap-2 m-auto overflow-auto overflow-y-auto"
    >
      @for (plan of plans; track plan) {
        <div
          class="flex flex-col items-center content-center rounded-lg min-w-36 border-solid border-2 border-black/10 hover:bg-accent-600/5 transition-all cursor-pointer hover:border-accent-500/10"
          (click)="
            createSessionLink(plan);
            analyticsService.logEvent(
              'admin_plan_' + plan.name + '_choice_click'
            )
          "
        >
          <div
            class="w-full px-4 py-2 gap-y-1 gap-x-2 flex md:flex-col items-center justify-center bg-accent-600/10 box-border"
          >
            <h1>{{ plan.name }}</h1>
            <h2>{{ plan.price }}</h2>
          </div>
          <!-- features -->
          <ul
            class="py-3 px-4 pl-6 flex-1 w-full box-border m-0 leading-normal"
          >
            @for (feature of plan.features; track feature) {
              <li>{{ feature }}</li>
            }
          </ul>
          <!-- button -->
          <div class="pt-0 p-4 w-full box-border">
            <button class="w-full">Testar grátis</button>
          </div>
        </div>
      }
    </div>
    <!-- footer -->
    <p class="text-sm">
      Dúvidas?
      <a
        class="font-bold text-accent-600"
        href="https://wa.me/559181052869?text=Ol%C3%A1,+gostaria+de+ajuda+para+escolher+meu+plano"
        >Entre em contato conosco por WhatsApp.</a
      >
    </p>
  }
</div>
