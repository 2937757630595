import { Injectable } from '@angular/core';
import {
  getAuth,
  NextOrObserver,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  Unsubscribe,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { AdminLoginError, AuthUser } from 'libs/shared/src/lib/interfaces';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { UserService } from './user.service';
import { signInWithCustomToken } from '@angular/fire/auth';
import { environment } from 'libs/shared/src/lib/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginError = new ReplaySubject<string>(1);
  loginSuccess = new ReplaySubject<string>(1);

  private auth = getAuth();

  constructor(
    private userService: UserService,
    private http: HttpClient,
  ) {
    this.onAuthStateChanged((user) => {
      if (!user) return this.userService.initializeEmptyUser();
      else if (user.email) this.userService.initializeAdmin(user.uid);
      else this.userService.initializeUser(user.uid);
    });
  }

  async register(
    email: string,
    password: string,
    displayName: string,
    phoneNumber?: string,
    signIn = true,
  ): Promise<void | AuthUser> {
    const body = {
      email,
      password,
      displayName,
      phoneNumber: phoneNumber && `+55${phoneNumber}`,
    };
    try {
      const userAuth = await firstValueFrom(
        this.http.post<any>(environment.cloudFunctionsApiUrl + '/auth', body),
      );
      if (signIn) await this.signInWithCustomToken(userAuth.token);
      return userAuth;
    } catch (error) {
      let errorCode =
        (error as any).error?.error ||
        (error as any).code ||
        (error as any).error;
      const code = (error as any).error?.code;
      if (code && code === 'auth/email-already-exists' && signIn) {
        errorCode = `Email já em uso. Se este é o seu email, por favor <a class="link" href="https://blu.direct/sign-in/admin?email=${email}">faça o login</a>.`;
      }
      this.displayErrorMessage(errorCode);
    }
  }

  onAuthStateChanged(nextOrObserver: NextOrObserver<User>): Unsubscribe {
    return onAuthStateChanged(this.auth, nextOrObserver);
  }

  async signIn(
    email: string | undefined,
    password: string | undefined,
  ): Promise<void> {
    if (!email || !password) return;
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      const errorCode = (error as any).code as keyof typeof AdminLoginError;
      const errorMessage = AdminLoginError[errorCode] || errorCode;
      this.loginError.next(errorMessage);
    }
  }

  async resetPassword(email: string | undefined): Promise<void> {
    if (!email) return this.displayErrorMessage('no-email');

    try {
      await sendPasswordResetEmail(this.auth, email);
      const successMessage =
        'Email enviado com as instruções para resetar a senha.';
      this.loginError.next('');
      this.loginSuccess.next(successMessage);
    } catch (error) {
      const errorCode = (error as any).code;
      this.displayErrorMessage(errorCode);
    }
  }

  displayErrorMessage(errorCode: string) {
    const errorMessage =
      AdminLoginError[errorCode as keyof typeof AdminLoginError] || errorCode;
    this.loginError.next(errorMessage);
  }

  async signInWithCustomToken(token: string): Promise<UserCredential> {
    return signInWithCustomToken(this.auth, token);
  }
}
