@if (channel) {
  <shared-modal [mobile]="sharedService.screenSize !== 'l'">
    <!-- header -->
    <h2 slot="header">Iniciar aquecimento</h2>

    <!-- body -->
    <div class="flex flex-col gap-3">
      <kor-input
        label="Nome"
        [value]="channel.name"
        (input)="setName($event.target)"
      ></kor-input>
      <div class="grid gap-[--gap-m] [grid-template-columns:80px_80px_1fr]">
        <kor-input
          no-clear
          label="País"
          maxlength="2"
          [value]="number.countryCode"
          (input)="setNumber('countryCode', $event, 2)"
        ></kor-input>
        <kor-input
          no-clear
          label="DDD"
          maxlength="2"
          [value]="number.areaCode"
          (input)="setNumber('areaCode', $event, 2)"
        ></kor-input>
        <kor-input
          no-clear
          label="Número"
          maxlength="8"
          [value]="number.number"
          (input)="setNumber('number', $event, 8)"
        ></kor-input>
      </div>
    </div>

    <!-- footer -->
    <kor-button
      slot="footer"
      color="secondary"
      label="Cancelar"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('admin_close_warmup_modal')
      "
    >
    </kor-button>
    <kor-button
      slot="footer"
      label="Confirmar"
      [disabled]="!channel.name || !channel.numbers.length"
      (click)="save(); analyticsService.logEvent('admin_save_warmup_click')"
    >
    </kor-button>
  </shared-modal>
}
