import {
  AfterViewInit,
  Component,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { IContact, ICsvContact } from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import {
  ContactListService,
  SourceFilters,
} from '@app/services/contact-list.service';
import { SendService } from '../../../../services/send.service';
import { SharedService } from '../../../../services/shared.service';
import { UserService } from '../../../../services/user.service';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { BluApiService } from '@app/services/blu-api.service';
import {
  ToastMessage,
  ToastService,
} from 'libs/shared/src/lib/services/toast.service';

@Component({
  selector: 'blu-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss'],
})
export class RecipientsComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  filterContactsVisible = false;
  paginationEnd = 100;
  refreshDisabled = false;

  constructor(
    public userService: UserService,
    public sendService: SendService,
    public sharedService: SharedService,
    public bluApi: BluApiService,
    public contactListService: ContactListService,
    public analyticsService: AnalyticsService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    if (this.contactListService.allContacts.length) return;
    this.sharedService.scrollBottom.subscribe(() => (this.paginationEnd += 20));
    this.contactListService.getContacts().then(() => {
      if (this.contactListService.allContacts.length < 100) {
        this.toastService.createToast({
          title: 'Chip recém cadastrado no WhatsApp',
          message: ToastMessage.NewSimCardWarning,
          icon: 'warning',
          iconColor: 'rgb(var(--functional-yellow))',
        });
      }
    });
  }

  ngAfterViewInit(): void {
    fromEvent<Event>(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((event: Event) => this.search(event));
  }

  search(event: Event) {
    this.analyticsService.logEvent('user_recipients_search_input');
    const searchString = (event.target as HTMLInputElement).value;
    this.contactListService.setSearchFilter(searchString);
  }

  toggleActive(contact: IContact | ICsvContact): void {
    contact.checked = !contact.checked;

    const chatId = contact.whatsappId;
    const index = this.sendService.getContactIndex(chatId);
    if (index > -1) {
      this.sendService.recipients.splice(index, 1);
      if (this.sendService.recipients.length === 0) {
        // reset source if list is empty
        this.sendService.recipientSource = SourceFilters.WHATSAPP;
      }
    } else {
      this.sendService.addRecipient({ chatId: chatId }); // TODO: this doesn't work for csv imports
      this.sendService.recipientSource = SourceFilters.WHATSAPP;
    }
  }

  reloadContacts() {
    this.contactListService.resetAllContacts();
    this.contactListService.getContacts();
    this.refreshDisabled = true;
    setTimeout(() => {
      this.refreshDisabled = false;
    }, 5000);
  }

  openFilterDrawer() {
    this.filterContactsVisible = true;
  }
}
