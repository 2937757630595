<shared-modal width="320px" [mobile]="sharedService.screenSize !== 'l'">
  <!-- header -->
  <h2 slot="header" class="flex">
    Ativar canal

    <!-- footer -->
    <kor-icon
      button
      class="ml-auto"
      icon="close"
      (click)="sharedService.closeModal(route)"
    ></kor-icon>
  </h2>

  <!-- body -->
  <!-- TODO: replace ngIf syntax with @if -->
  <ng-container
    *ngIf="{
      qr: bluApi.qr$ | async,
      isLoading: bluApi.loadingIndicator$ | async,
      qrError: bluApi.qrError
    } as observable"
  >
    @if (observable.qr && !observable.isLoading && !observable.qrError) {
      <qrcode
        class="grid-overlap mx-auto"
        [qrdata]="observable.qr"
        [width]="200"
      ></qrcode>
    } @else {
      <!-- TODO: add overlap content -->
      <ng-template #overlap>Carregando...</ng-template>
    }
  </ng-container>

  <p>Escaneie o código acima no WhatsApp para ativar a sessão.</p>
</shared-modal>
