import { Injectable } from '@angular/core';
import {
  Analytics,
  getAnalytics,
  logEvent,
  getGoogleAnalyticsClientId,
} from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private analytics: Analytics = getAnalytics()) {}

  logEvent(name: string, options?: any): void {
    logEvent(this.analytics, name, options);
  }

  getGoogleAnalyticsClientId() {
    return getGoogleAnalyticsClientId(this.analytics);
  }
}
