import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FirestoreCollections,
  ITemplate,
} from 'libs/shared/src/lib/interfaces';
import { BluApiService } from '../../../services/blu-api.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'blu-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})
export class ScanComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public bluApi: BluApiService,
    private firestore: SharedFirestoreService,
  ) {}

  async ngOnInit(): Promise<void> {
    const templateId = this.route.snapshot.paramMap.get('id');
    if (!templateId) return;
    // check if template exists
    const templateDocData = await this.firestore.getDoc<ITemplate>(
      FirestoreCollections.Template,
      templateId,
    );
    // if not, redirect to error page
    if (!templateDocData) {
      this.router.navigateByUrl('/error/template');
      return;
    }
    // if so, generate qr
    this.bluApi.initializeTemplateQR(templateId, () =>
      this.router.navigate(['send'], { relativeTo: this.route }),
    );
  }
}
