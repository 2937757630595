@if (!feedbackService.didRate) {
  Como foi sua experiência hoje?
  <div class="stars">
    @for (star of [].constructor(5); track star; let i = $index) {
      <kor-icon
        button
        [icon]="feedbackService.rating >= i ? 'star' : 'star_outline'"
        (mouseenter)="hoverIndex = i"
        (mouseleave)="hoverIndex = feedbackService.rating"
        (click)="feedbackService.rating = i"
        [ngClass]="{ yellow: i <= hoverIndex }"
        [routerLink]="[{ outlets: { modal: ['feedback'] } }]"
      >
      </kor-icon>
    }
  </div>
} @else {
  Obrigado pelo feedback!
}

<router-outlet name="modal"></router-outlet>
