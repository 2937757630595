import { Component, Input } from '@angular/core';

@Component({
  selector: 'blu-stepper-item',
  templateUrl: './stepper-item.component.html',
  styleUrls: ['./stepper-item.component.scss'],
})
export class StepperItemComponent {
  @Input() icon: string = 'https://img.icons8.com/fluency/2x/inscription.png';
  @Input() label?: string;
}
