<!-- header -->
<header class="header md:flex-row flex-col !items-start md:!items-center">
  <h2 class="mr-auto flex items-conter gap-2">
    {{ contactListService.contactsForDisplay.length || 0 }} contatos
  </h2>
  <!-- search -->
  <input
    type="search"
    class="md:max-w-96 w-full md:w-auto md:flex-1"
    placeholder="Buscar por nome ou número"
    #searchInput
  />
  <!-- buttons -->
  <div class="flex gap-2 w-full md:w-auto">
    <button
      class="tertiary mr-auto"
      [routerLink]="[{ outlets: { modal: ['upload-contacts'] } }]"
      (click)="analyticsService.logEvent('user_recipients_upload_click')"
    >
      Importar lista
    </button>
    <button
      class="secondary px-1"
      [disabled]="refreshDisabled"
      (click)="
        reloadContacts();
        analyticsService.logEvent('user_recipients_refresh-contacts_click')
      "
    >
      <span class="material-symbols-rounded"> refresh </span>
    </button>
    <button
      class="secondary px-1"
      (click)="
        openFilterDrawer();
        analyticsService.logEvent('user_recipients_filter_click')
      "
    >
      <span class="material-symbols-rounded"> filter_list </span>
    </button>
    <button
      class="px-1"
      (click)="
        contactListService.selectAll();
        analyticsService.logEvent('user_recipients_select-all_click')
      "
    >
      <span class="material-symbols-rounded"> done_all </span>
    </button>
  </div>
</header>

<!-- body -->
@if (!contactListService.isLoading) {
  @if (contactListService.contactsForDisplay.length > 0) {
    <div class="flex flex-col gap-3 mb-4 py-[5px]">
      <!-- contact card -->
      <!-- TODO: change ngFor syntax to @for -->
      <div
        class="card button !flex-row !gap-3 !p-3 items-center"
        *ngFor="
          let contact of contactListService.contactsForDisplay
            | slice: 0 : paginationEnd
        "
        [ngClass]="{
          active: contact.checked,
          disabled:
            sendService.recipients.length >= sendService.messageLimitNumber &&
            !contact.checked
        }"
        (click)="
          toggleActive(contact);
          analyticsService.logEvent('user_recipients_contact-card_click')
        "
      >
        <kor-checkbox [active]="contact.checked"></kor-checkbox>
        <h2 class="flex flex-1 gap-x-3 flex-col md:flex-row">
          <div>
            @if (!contact.isGroup) {
              <b>
                {{
                  contact.number
                    | mask: '+00 (00) 0000-0000||+00 (00) 0 0000-0000'
                }}
              </b>
            } @else {
              <kor-tag label="Grupo"></kor-tag>
            }
          </div>
          <div>
            @if (!contact.fromCsv) {
              <p>{{ contact.name }}</p>
            } @else {
              <kor-tag label="Importado"></kor-tag>
            }
          </div>
        </h2>
        <!-- last campaign date -->
        @if (contact.lastCampaignDate) {
          <p class="text-black/60 text-xs">
            {{ contact.lastCampaignDate | date: 'MMM dd H:mm' }}
          </p>
        }
      </div>
    </div>
  } @else {
    <kor-empty-state
      icon="contacts"
      label="Não há contatos correspondentes aos filtros aplicados."
    ></kor-empty-state>
  }
} @else {
  <kor-spinner
    [label]="'Sincronizando contatos' | loading | async"
    style="white-space: break-spaces"
  ></kor-spinner>
}

<!-- alert -->
@if (sendService.recipients.length >= sendService.messageLimitNumber) {
  <p class="!justify-start footer bottom-[72px] !gap-2">
    <span class="material-symbols-rounded text-yellow-600"> warning </span>
    Limite por disparo atingido ({{ sendService.messageLimitNumber }}).
  </p>
}

<!-- footer -->
<footer class="footer">
  @if (contactListService.activeFilters.deleted) {
    <button
      class="secondary px-1"
      icon="restore_from_trash"
      [disabled]="sendService.recipients.length < 1"
      (click)="
        contactListService.restoreContacts();
        analyticsService.logEvent('user_recipients_restore_contact_click')
      "
    >
      <span class="material-symbols-rounded"> restore_from_trash </span>
    </button>
  } @else {
    <button
      class="secondary px-1 mr-auto"
      icon="delete"
      [disabled]="sendService.recipients.length < 1"
      (click)="
        contactListService.deleteContacts();
        analyticsService.logEvent('user_recipients_delete_contact_click')
      "
    >
      <span class="material-symbols-rounded"> delete </span>
    </button>
  }
  <button
    [routerLink]="'../trigger'"
    [disabled]="sendService.recipients.length < 1"
    (click)="
      sendService.setRecipients(sendService.recipients);
      analyticsService.logEvent('user_recipients_confirm_click')
    "
  >
    Escolher {{ sendService.recipients.length }} contatos
  </button>
</footer>

<!-- filter drawer -->
@if (filterContactsVisible) {
  <blu-filter-drawer
    (close)="filterContactsVisible = false"
  ></blu-filter-drawer>
}

<!-- modal -->
<router-outlet name="modal"></router-outlet>
