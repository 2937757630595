@if (loading) {
  <kor-spinner label="Finalizando registro"></kor-spinner>
} @else {
  <form
    [formGroup]="registrationForm"
    (ngSubmit)="onSubmit()"
    class="flex flex-col gap-4 flex-1"
  >
    <p class="text-center mt-auto">
      Forneça seu e-mail e escolha sua senha e nome da empresa para realizar o
      registro.
    </p>
    <!-- inputs -->
    <div class="flex flex-col gap-2">
      <input
        id="email"
        formControlName="email"
        type="email"
        placeholder="Email"
        required
        autocomplete="email"
      />
      <input
        id="password"
        formControlName="password"
        type="password"
        placeholder="Senha"
        required
        autocomplete="new-password"
      />
      <input
        id="orgName"
        formControlName="orgName"
        type="text"
        placeholder="Nome da empresa"
        required
        autocomplete="organization"
      />
      <input
        id="phoneNumber"
        formControlName="phoneNumber"
        type="tel"
        placeholder="Telefone"
        required
        autocomplete="tel"
        maxlength="11"
        pattern="[0-9]*"
        inputmode="numeric"
      />
    </div>

    <p
      [innerHTML]="authService.loginError | async"
      class="font-bold min-h-6 text-red-600"
    ></p>

    <!-- cta -->
    <button
      type="submit"
      class="mt-auto w-full max-w-none box-border"
      [disabled]="!registrationForm.valid"
    >
      Registrar
    </button>
  </form>
}
