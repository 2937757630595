import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from '@app/services/feedback.service';
import { SharedService } from '@app/services/shared.service';

@Component({
  selector: 'blu-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent {
  constructor(
    public sharedService: SharedService,
    public route: ActivatedRoute,
    public feedbackService: FeedbackService,
  ) {}
}
