import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SendService } from '@app/services/send.service';
import { SharedService } from '@app/services/shared.service';

@Component({
  selector: 'blu-add-poll-option',
  templateUrl: './add-poll-option.component.html',
  styleUrls: ['./add-poll-option.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class AddPollOptionComponent {
  pollOption?: string;

  constructor(
    public route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public sharedService: SharedService,
    private sendService: SendService,
  ) {}

  setPollOption(e: Event): void {
    let value: string | undefined = (e.target as HTMLInputElement).value;
    if (value === '') value = undefined;
    this.pollOption = value;
  }

  createPollOption() {
    if (!this.pollOption) return;
    this.sendService.content.poll.options.push(this.pollOption);
  }

  formatNumber(text: string): string | undefined {
    const numUrl = text?.replace(/([-+() ])/g, '');
    const isNumber = parseInt(numUrl, 10);
    if (!isNumber) return;
    return numUrl.toString();
  }

  formatUrl(text: string): string {
    const httpsPrefix = 'https://';
    const httpPrefix = 'http://';
    if (text.startsWith(httpsPrefix) || text.startsWith(httpPrefix)) {
      return text;
    }
    return httpsPrefix + text;
  }
}
