import { Injectable } from '@angular/core';
import * as shape from 'd3-shape';
import { DataItem, ScaleType } from '@swimlane/ngx-charts';
import { UserService } from './user.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  where,
} from '@firebase/firestore';
import { IStats } from 'libs/shared/src/lib/interfaces';
import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
} from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  settings = {
    curve: shape.curveMonotoneX,

    colorScheme: {
      name: 'blu',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: ['#1192e8', '#a56eff', '#009d9a', '#9f1853', '#ee538b'],
    },
    colorScheme2: {
      name: 'blu',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: [
        'rgba(var(--functional-green), .8)',
        'rgba(var(--functional-blue), .8)',
        'rgba(var(--functional-red), .8)',
      ],
    },
    colorScheme3: {
      name: 'blu',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: ['#1192e8'],
    },
  };
  messages = {
    currentPeriod: {
      name: 'Mensagens',
      series: <DataItem[]>[],
      sum: 0,
    },
    previousPeriod: {
      name: 'Mensagens',
      series: <DataItem[]>[],
      sum: 0,
    },
  };
  campaigns = {
    currentPeriod: {
      name: 'Campanhas',
      series: <DataItem[]>[],
      sum: 0,
    },
    previousPeriod: {
      name: 'Campanhas',
      series: <DataItem[]>[],
      sum: 0,
    },
  };

  dataLoaded = new BehaviorSubject<string>('');

  constructor(
    private userService: UserService,
    private firestore: SharedFirestoreService,
    private sharedService: SharedService,
  ) {
    const org = this.userService.organization();
    if (!org) return;
    this.getData(org.uid, 'currentPeriod', { from: 30, to: 0 });
    this.getData(org.uid, 'previousPeriod', { from: 60, to: 30 });
  }

  private async getData(
    orgId: string,
    period: 'currentPeriod' | 'previousPeriod',
    dates: { from: number; to: number },
  ): Promise<void> {
    // get array of stats for given dates
    const statsArray = await this.getStatsBetweenDates(
      `organization/${orgId}`,
      this.sharedService.getPastDate(dates.from),
      this.sharedService.getPastDate(dates.to),
    );
    statsArray.forEach(async (statsDoc: IStats) => {
      if (!statsDoc.lastCampaignDate) return;
      const date = this.modelDate(statsDoc.lastCampaignDate);
      // construct messages and campaigns array
      if (statsDoc.messagesSentCount) {
        const series = this.messages[period].series;
        series.push({
          name: date,
          value: statsDoc.messagesSentCount,
        });
        this.messages[period].sum = this.sumArrayValue(series);
      }
      if (statsDoc.campaignsCount) {
        const series = this.campaigns[period].series;
        series.push({
          name: date,
          value: statsDoc.campaignsCount,
        });
        this.campaigns[period].sum = this.sumArrayValue(series);
      }
      // emit data loaded
      this.dataLoaded.next('messages');
    });
  }

  // get
  async getAllOrgDailyStats(daysAgo: number): Promise<DataItem[]> {
    const dateFrom = new Date(this.sharedService.getPastDate(daysAgo));
    // get daily stats across all orgs within time period
    const dailyStatsQuery = query(
      collectionGroup(this.firestore.afs, 'dailyStats'),
      where('lastCampaignDate', '>=', dateFrom),
      where('scope', '==', 'organization'),
    );
    const dailyStatsQuerySnap = await getDocs(dailyStatsQuery);
    const response: DataItem[] = [];
    dailyStatsQuerySnap.forEach(async (doc) => {
      const dailyStatsDocData = doc.data() as IStats;
      if (!dailyStatsDocData.lastCampaignDate) return;
      const date = this.modelDate(dailyStatsDocData.lastCampaignDate);
      // check if date already exists in response array
      const existingItem = response.filter((stat) => {
        return (<Date>stat.name).getDate() === date.getDate();
      })[0];
      // if so, increment count
      if (existingItem)
        existingItem.value += dailyStatsDocData.campaignsCount || 0;
      // if not, push new item to array
      else
        response.push({
          name: date,
          value: dailyStatsDocData.campaignsCount || 0,
        });
    });
    return response;
  }

  // transform a timestamp into absolute date
  public modelDate(timestamp: Timestamp): Date {
    const date = timestamp.toDate();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public async getStatsBetweenDates(
    docPath: string,
    dateFrom: Date,
    dateTo: Date,
  ): Promise<IStats[]> {
    dateFrom.setDate(dateFrom.getDate() + 1);
    dateTo.setDate(dateTo.getDate() + 1);
    // then build daily and monthly stats ref
    const querySnap = await this.firestore.getDocs<IStats>(
      `${docPath}/dailyStats`,
      where('lastCampaignDate', '>', dateFrom),
      where('lastCampaignDate', '<=', dateTo),
    );
    const statsArray: IStats[] = [];
    querySnap.docs.forEach((docSnap: QueryDocumentSnapshot<IStats>) => {
      statsArray.push(docSnap.data());
    });
    return statsArray;
  }

  async getRankedStatsForOrg(
    key: string,
    orgId: string,
    amount: number,
  ): Promise<(IStats | undefined)[]> {
    // model doc id
    const dateParts = new Date().toISOString().split('T')[0].split('-');
    const monthString = `${dateParts[0]}${dateParts[1]}`;
    // query collectionGroup monthlyStats
    const monthlyStatsQuery = query(
      collectionGroup(this.firestore.afs, 'monthlyStats'),
      where('uid', '==', monthString),
      where('scope', '==', 'user'),
      where('organization', '==', orgId),
      orderBy(key, 'desc'),
      limit(amount),
    );
    const monthlyStatsQuerySnap = await getDocs(monthlyStatsQuery);
    const response: (IStats | undefined)[] = [];
    monthlyStatsQuerySnap.docs.forEach((doc: DocumentSnapshot<IStats>) => {
      response.push(doc.data());
    });
    return response;
  }

  sumArrayValue(arr: any[]): number {
    return arr.reduce(
      (accumulator, current) => accumulator + Number(current.value),
      0,
    );
  }
}
