@if (toastService.shouldShow) {
  <section class="card cursor-pointer z-50" (click)="toastService.hide()">
    @if (toastService.icon) {
      <kor-icon
        [style.color]="toastService.iconColor"
        [icon]="toastService.icon"
        size="m"
      ></kor-icon>
    }
    <div>
      <b>{{ toastService.title }}</b> <br />
      <span>{{ toastService.message }}</span>
    </div>
  </section>
}
