import { Injectable } from '@angular/core';
import { SharedAuthService } from 'libs/shared/src/lib/services/auth.service';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import {
  ToastMessage,
  ToastService,
} from 'libs/shared/src/lib/services/toast.service';
import { environment } from 'libs/shared/src/lib/environments/environment';

export enum WsEvent {
  ChatList = 'chatList',
  ChatMessages = 'chatMessages',
  NewMessage = 'newMessage',
  SendMessage = 'sendMessage',
  setBluLabel = 'setBluLabel',
  Error = 'error',
}

@Injectable({ providedIn: 'root' })
export class WsService {
  channelToSocket: Record<string, Socket> = {};

  constructor(
    private sharedAuthService: SharedAuthService,
    private toastsService: ToastService,
  ) {}

  async connect(channelId: string) {
    const socket = new Socket({
      url: `${environment.bluApiUrl}?s=${channelId}`,
      options: {
        auth: { token: await this.sharedAuthService.getToken() },
        reconnection: false,
        transports: ['websocket'],
      },
    });
    this.channelToSocket[channelId] = socket;
    let didError = false;
    socket.on('error', ({ code }: { code: string }) => {
      didError = true;
      let message;
      switch (code) {
        case 'CONNECTED_FROM_DIFFERENT_WINDOW':
          message = ToastMessage.ConnectedFromAnotherWindow;
          break;
        case 'SUBSCRIPTION_EXPIRED':
          message = ToastMessage.SubscriptionExpired;
          break;
        default:
          message = ToastMessage.ConnectionLost;
      }

      this.toastsService.createToast({
        title: 'Erro',
        message,
        icon: 'error',
        iconColor: 'rgb(var(--functional-red))',
      });
    });
    socket.on('disconnect', () => {
      if (didError) return;
      this.toastsService.createToast({
        title: 'Conexão perdida',
        message: ToastMessage.ConnectionLost,
        icon: 'warning',
        iconColor: 'rgb(var(--functional-yellow))',
      });
    });
  }

  emit(channelId: string, eventName: WsEvent, ...data: any[]) {
    this.channelToSocket[channelId]?.emit(eventName, ...data);
  }

  fromEvent<T>(channelId: string, eventName: string): Observable<T> {
    return this.channelToSocket[channelId]?.fromEvent(eventName);
  }
}
