import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'blu-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class AdminNavBarComponent {
  constructor(
    public router: Router,
    public sharedService: SharedService,
    public analyticsService: AnalyticsService,
    public userService: UserService,
  ) {}

  @Output() close = new EventEmitter();
}
