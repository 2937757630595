<shared-modal [mobile]="sharedService.screenSize !== 'l'">
  <h2 slot="header">{{ alertService.title }}</h2>
  <p [innerHTML]="alertService.message"></p>
  <kor-button
    slot="footer"
    color="secondary"
    (click)="cancel()"
    [label]="alertService.cancelButtonText || 'Cancelar'"
  >
  </kor-button>
  @if (alertService.confirmButtonText) {
    <kor-button
      slot="footer"
      (click)="confirm()"
      [label]="alertService.confirmButtonText"
    >
    </kor-button>
  }
</shared-modal>
