import { Component, Input } from '@angular/core';

@Component({
  selector: 'blu-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent {
  @Input() label = '–';
  @Input() title?: string;
  @Input() value?: number;
  @Input() trend?: number;
  @Input() button = false;
  @Input() active = false;

  trendDirection?: 'positive' | 'negative';
}
