import { Component, EventEmitter, Output } from '@angular/core';
import {
  ContactListService,
  GroupFilters,
  SourceFilters,
  TimeFilters,
} from '@app/services/contact-list.service';

@Component({
  selector: 'blu-filter-drawer',
  templateUrl: './filter-drawer.component.html',
  styleUrls: ['./filter-drawer.component.scss'],
})
export class FilterDrawerComponent {
  @Output() close = new EventEmitter<void>();
  public TimeFilters = TimeFilters;
  public SourceFilters = SourceFilters;
  public GroupFilters = GroupFilters;

  constructor(public contactListService: ContactListService) {}

  activeFilter: number | undefined;
  searchString?: string;
}
