import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthUser, IOperator } from 'libs/shared/src/lib/interfaces';
import { AuthService } from '@app/services/auth.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { OperatorsService } from '@app/services/operators.service';
import { AnalyticsService } from '@shared/services/analytics.service';

@Component({
  selector: 'blu-add-operator',
  templateUrl: './add-operator.component.html',
  styleUrls: ['./add-operator.component.scss'],
})
export class AddOperatorComponent implements OnInit {
  operator?: IOperator;
  credentials: {
    email?: string;
    password?: string;
    displayName?: string;
  } = {
    email: undefined,
    password: undefined,
    displayName: undefined,
  };

  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public authService: AuthService,
    private userService: UserService,
    private operatorsService: OperatorsService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.getOperator(id);
  }

  private async getOperator(id: string): Promise<void> {
    this.operator = await this.operatorsService.getOperator(id);
  }

  setName(tar: EventTarget | null): void {
    if (!this.operator) return;
    this.credentials.displayName = (tar as HTMLInputElement).value;
  }

  setCredentials(
    key: 'email' | 'password' | 'displayName',
    tar: EventTarget | null,
  ): void {
    const value = (tar as HTMLInputElement).value;
    this.credentials[key] = value;
  }

  async delete(): Promise<void> {
    const operatorId = this.operator?.uid;
    const orgId = this.userService.organization()?.uid;
    if (!operatorId || !orgId) {
      throw new Error('Operator or organization not found');
    }
    await this.operatorsService.deleteOperator(operatorId, orgId);
    this.sharedService.closeModal(this.route);
  }

  async save(): Promise<void> {
    if (
      !this.credentials.email ||
      !this.credentials.password ||
      !this.credentials.displayName
    ) {
      return;
    }

    if (this.operator) return;
    const authUser = await this.authService.register(
      this.credentials.email,
      this.credentials.password,
      this.credentials.displayName,
      undefined,
      false,
    );
    if (!authUser) return;
    await this.setOperatorDoc(authUser);
    this.sharedService.closeModal(this.route);
    // TODO: handle errors from registration
  }

  setOperatorDoc(authUser: AuthUser) {
    const orgId = this.userService.organization()?.uid;
    if (!orgId) return;
    return this.operatorsService.setOperator(authUser.uid, {
      uid: authUser.uid,
      name: authUser.displayName,
      email: authUser.email,
      createdAt: new Date(),
      deletedAt: null,
      organization: orgId,
    });
  }
}
