import { Injectable } from '@angular/core';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  async resizeImage(file: File, max = 800) {
    // ensure the file is an image
    if (!file.type.match(/image.*/)) {
      throw new Error('Tentando redimensionar um arquivo que não é uma imagem');
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);

    await new Promise<Event>((res) => (image.onload = res));
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d', { alpha: true });

    let width = (await this.getImageDimensions(file)).width;
    let height = (await this.getImageDimensions(file)).height;

    if (width >= height && width > max) {
      height *= max / width;
      width = max;
    } else if (height > width && height > max) {
      width *= max / height;
      height = max;
    }

    canvas.width = width;
    canvas.height = height;
    context?.drawImage(image, 0, 0, width, height);

    const blob = await new Promise<Blob | null>((res) =>
      canvas.toBlob(res, 'image/jpeg', 0.9),
    );
    if (!blob) throw new Error('Erro ao redimensionar imagem');
    return blob;
  }

  // get original dimensions of image file
  private getImageDimensions(
    file: File,
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = function () {
        window.URL.revokeObjectURL(objectUrl);
        resolve({ width: img.width, height: img.height });
      };
    });
  }

  // upload a file to firebase and push url to media
  async uploadBlobToFirebase(
    name: string,
    blob: Blob,
    folder = 'media',
  ): Promise<string> {
    const storage = getStorage();
    const storageRef = ref(storage, `${folder}/${name}`);
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(ref(storage, `${folder}/${name}`));
    return url;
  }

  async deleteFileFromFirebase(url: string) {
    const storage = getStorage();
    const storageRef = ref(storage, url);
    deleteObject(storageRef);
  }
}
