import { Injectable, inject } from '@angular/core';
import { orderBy, serverTimestamp, where } from '@angular/fire/firestore';
import {
  ChannelStatus,
  FirestoreCollections,
  IChannel,
  IHydratedChannel,
  IWarmupChannel,
  OnboardingStepKey,
} from 'libs/shared/src/lib/interfaces';
import { SharedBluApiService } from 'libs/shared/src/lib/services/blu-api.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { AlertService } from './alert.service';
import { OnboardingService } from './onboarding.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  private firestore = inject(SharedFirestoreService);
  private bluApiService = inject(SharedBluApiService);
  private onboardingService = inject(OnboardingService);
  private alertService = inject(AlertService);

  chatboChannels: IHydratedChannel[] = [];
  warmupChannels: IWarmupChannel[] = [];

  async getWarmupChannels(orgId: string) {
    if (this.warmupChannels.length) return;

    const channelsSnap = await this.firestore.getDocs(
      FirestoreCollections.Channel,
      where('startedWarmingUpAt', '!=', null),
      where('organization', '==', orgId),
      orderBy('createdAt', 'desc'),
    );
    this.warmupChannels = channelsSnap.docs.map((doc) => {
      const ch = doc.data() as IWarmupChannel;
      ch.startedWarmingUpAt = (ch.startedWarmingUpAt as any)?.toDate();
      ch.temperature = this.calculateWarmupTemperature(ch);
      return ch;
    });

    for (const channel of this.warmupChannels) {
      if (!channel.uid) return;
      try {
        const res = (await this.bluApiService.getChannelStatus(
          channel.uid,
        )) as any;
        channel.status = res.status;
      } catch (error) {
        console.error(error);
      }
    }
  }

  async getChannels(orgId: string) {
    if (this.chatboChannels.length) return;

    const channelsSnap = await this.firestore.getDocs(
      FirestoreCollections.Channel,
      where('deletedAt', '==', null),
      where('organization', '==', orgId),
      orderBy('createdAt', 'desc'),
    );
    this.chatboChannels = channelsSnap.docs.map(
      (doc) => doc.data() as IHydratedChannel,
    );

    for (const channel of this.chatboChannels) {
      if (!channel.uid) return;
      try {
        const res = (await this.bluApiService.getChannelStatus(
          channel.uid,
        )) as any;
        channel.status = res.status;
      } catch (error) {
        console.error(error);
      }
    }
  }

  async getChannel(channelId: string): Promise<IChannel | undefined> {
    if (this.chatboChannels.length) {
      return this.chatboChannels.find((channel) => channel.uid === channelId);
    }
    return this.firestore.getDoc<IHydratedChannel>(
      FirestoreCollections.Channel,
      channelId,
    );
  }

  async getChannelByNumber(number: string) {
    const ch = this.chatboChannels.find((channel) =>
      channel.numbers.includes(number),
    );
    if (ch) return ch;
    const chs = await this.firestore.getDocs<IHydratedChannel>(
      FirestoreCollections.Channel,
      where('numbers', 'array-contains', number),
    );
    if (!chs.size) return;
    return chs.docs[0].data();
  }

  async updateChannel(
    channelId: string,
    hydratedChannel: Partial<IHydratedChannel>,
  ): Promise<void> {
    const channel = { ...hydratedChannel };
    delete channel.status;
    await this.firestore.updateDoc(
      FirestoreCollections.Channel,
      channelId,
      channel,
    );
    const index = this.chatboChannels.findIndex((c) => c.uid === channelId);
    if (index > -1) {
      this.chatboChannels[index] = Object.assign(
        this.chatboChannels[index],
        channel,
      );
    }
  }

  async createChannel(channel: IChannel): Promise<void> {
    const existingChannel = await this.getChannelByNumber(channel.numbers[0]);
    if (existingChannel && existingChannel.uid) {
      if (existingChannel.organization !== channel.organization) {
        this.alertService.alert({
          title: 'Número já utilizado',
          message:
            'Esse número já está cadastrado em outra organização. Fale conosco para transferí-lo.',
        });
        return;
      }
      await this.firestore.updateDoc(
        FirestoreCollections.Channel,
        existingChannel.uid,
        {
          name: channel.name,
          deletedAt: null,
          operators: channel.operators,
        },
      );
      this.chatboChannels.push({ ...existingChannel } as IHydratedChannel);
      return;
    }
    const chRef = await this.firestore.addDoc(
      FirestoreCollections.Channel,
      channel,
    );
    this.onboardingService.completeStep(OnboardingStepKey.createdChannel);
    this.chatboChannels.push({
      ...channel,
      uid: chRef.id,
    } as IHydratedChannel);
  }

  async createWarmup(channel: IChannel): Promise<void> {
    const existingChannel = await this.getChannelByNumber(channel.numbers[0]);
    if (existingChannel && existingChannel.uid) {
      if (existingChannel.organization !== channel.organization) {
        this.alertService.alert({
          title: 'Número já utilizado',
          message:
            'Esse número já está cadastrado em outra organização. Fale conosco para transferí-lo.',
        });
        return;
      }
      await this.firestore.updateDoc(
        FirestoreCollections.Channel,
        existingChannel.uid,
        {
          name: channel.name,
          startedWarmingUpAt: serverTimestamp(),
        },
      );
      this.warmupChannels.push({
        ...existingChannel,
        startedWarmingUpAt: new Date(),
        temperature: 'frio',
      } as IHydratedChannel);
      return;
    }
    const chRef = await this.firestore.addDoc(
      FirestoreCollections.Channel,
      channel,
    );
    this.warmupChannels.push({
      ...channel,
      uid: chRef.id,
      startedWarmingUpAt: new Date(),
      temperature: 'frio',
    } as IWarmupChannel);
  }

  async deleteChannel(channelId: string): Promise<void> {
    await this.firestore.updateDoc(FirestoreCollections.Channel, channelId, {
      deletedAt: serverTimestamp(),
    });
    const index = this.chatboChannels.findIndex((c) => c.uid === channelId);
    if (index > -1) this.chatboChannels.splice(index, 1);
  }

  async deleteWarmup(channelId: string): Promise<void> {
    await this.firestore.updateDoc(FirestoreCollections.Channel, channelId, {
      startedWarmingUpAt: null,
    });
    const index = this.warmupChannels.findIndex((c) => c.uid === channelId);
    if (index > -1) this.warmupChannels.splice(index, 1);
  }

  setChannelToConnected(channelId: string): void {
    for (const channel of this.chatboChannels) {
      if (channel.uid === channelId) {
        channel.status = ChannelStatus.CONNECTED;
        break;
      }
    }
    for (const channel of this.warmupChannels) {
      if (channel.uid === channelId) {
        channel.status = ChannelStatus.CONNECTED;
        return;
      }
    }
  }

  calculateWarmupTemperature(channel: IChannel) {
    if (!channel.startedWarmingUpAt) return null;
    const twoWeeks = 2 * 7 * 24 * 60 * 60 * 1000;
    const timeElapsed =
      new Date().getTime() - channel.startedWarmingUpAt.getTime();
    const rate = Math.min(timeElapsed / twoWeeks, 1);
    if (rate === 1) {
      return 'quente';
    } else if (rate > 0.5) {
      return 'morno';
    } else {
      return 'frio';
    }
  }
}
