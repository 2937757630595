import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SendService } from '../../../services/send.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'blu-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss'],
})
export class SendComponent {
  constructor(
    public sendService: SendService,
    public sharedService: SharedService,
    public router: Router,
    public analyticsService: AnalyticsService,
  ) {}
}
