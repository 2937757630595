import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlowListService } from '@app/services/flow-list.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { IFlow } from 'libs/shared/src/lib/interfaces';

@Component({
  selector: 'blu-add-flow',
  templateUrl: './add-flow.component.html',
  styleUrls: ['./add-flow.component.scss'],
})
export class AddFlowComponent implements OnInit {
  flow: IFlow = {
    label: '',
    uid: '',
    organization: '',
  };
  constructor(
    public sharedService: SharedService,
    public route: ActivatedRoute,
    private userService: UserService,
    private flowListService: FlowListService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.flow = (await this.flowListService.getFlow(id)) as IFlow;
  }

  setLabel(tar: EventTarget | null): void {
    if (!this.flow) return;
    this.flow.label = (tar as HTMLInputElement).value;
  }

  async delete(): Promise<void> {
    this.flowListService.deleteFlow(this.flow.uid);
    this.sharedService.closeModal(this.route);
  }

  async save(): Promise<void> {
    if (!this.flow) return;
    if (this.flow.uid) {
      await this.flowListService.updateFlow(this.flow.uid, {
        ...this.flow,
        organization: this.userService.organization()?.uid as string,
      });
    } else await this.flowListService.createFlow(this.flow);
    this.sharedService.closeModal(this.route);
  }
}
