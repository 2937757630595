import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'libs/shared/src/lib/environments/environment';
import { ICsvImportResponse } from 'libs/shared/src/lib/interfaces';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CsvImportService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  async importCsv(file: File): Promise<ICsvImportResponse> {
    const user = this.userService.user();
    if (!user) throw new Error('User not logged in');
    const form = new FormData();
    form.append('fileKey', file, file.name);
    return await firstValueFrom(
      this.http.post<ICsvImportResponse>(
        environment.cloudFunctionsApiUrl + '/csvImport',
        form,
        { params: { userId: user.uid } },
      ),
    );
  }
}
