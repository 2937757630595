import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService } from '@app/services/categories.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'blu-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  constructor(
    public categoriesService: CategoriesService,
    public sharedService: SharedService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const org = this.userService.organization();
    if (!org) {
      this.router.navigate(['/sign-in/admin']);
      return;
    }
    await this.categoriesService.getCategories(org.uid);
  }
}
