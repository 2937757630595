<form
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit()"
  class="flex flex-col gap-4 flex-1"
>
  <p class="text-center mt-auto">
    Utilize suas credenciais para realizar login.
  </p>

  <!-- inputs -->
  <div class="flex flex-col gap-2">
    <input
      id="email"
      formControlName="email"
      type="email"
      required
      autocomplete="email"
      placeholder="Email"
      autofocus
    />
    <input
      id="password"
      formControlName="password"
      type="password"
      required
      autocomplete="current-password"
      placeholder="Senha"
    />
  </div>

  <!-- forgot password -->
  <a
    href="javascript:void(0);"
    (click)="
      resetPassword();
      analyticsService.logEvent('sign-in_admin_reset-password_click')
    "
    class="text-accent-600 font-bold"
    >Esqueceu sua senha?</a
  >

  <!-- messages -->
  <b class="font-bold text-green-600 min-h-6">{{
    authService.loginSuccess | async
  }}</b>
  <b class="font-bold min-h-6 text-red-600">{{
    authService.loginError | async
  }}</b>

  <!-- cta -->
  <button
    type="submit"
    [disabled]="!loginForm.valid"
    (click)="analyticsService.logEvent('sign-in_admin_sign-in_click')"
  >
    Entrar
  </button>
</form>
