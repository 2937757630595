import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './pages/admin/admin.component';
import { AddCategoryComponent } from './pages/admin/chat/categories/add-category/add-category.component';
import { CategoriesComponent } from './pages/admin/chat/categories/categories.component';
import { ActivateChannelComponent } from './pages/admin/chat/channels/activate-channel/activate-channel.component';
import { AddChannelComponent } from './pages/admin/chat/channels/add-channel/add-channel.component';
import { ChannelsComponent } from './pages/admin/chat/channels/channels.component';
import { SelectFlowComponent } from './pages/admin/chat/channels/select-flow/select-flow.component';
import { AddFlowComponent } from './pages/admin/chat/flows/add-flow/add-flow.component';
import { FlowComponent } from './pages/admin/chat/flows/flow/flow.component';
import { FlowsComponent } from './pages/admin/chat/flows/flows.component';
import { AddOperatorComponent } from './pages/admin/chat/operators/add-operator/add-operator.component';
import { OperatorsComponent } from './pages/admin/chat/operators/operators.component';
import { PlansComponent } from './pages/admin/plans/plans.component';
import { CampaignComponent } from './pages/admin/send/campaigns-list/campaign/campaign.component';
import { SendStatsComponent } from './pages/admin/send/stats/stats.component';
import { AddTemplateComponent } from './pages/admin/send/templates-list/add-template/add-template.component';
import { TemplatesListComponent } from './pages/admin/send/templates-list/templates-list.component';
import { AddUserTagComponent } from './pages/admin/send/users-list/add-user-tag/add-user-tag.component';
import { AddUserComponent } from './pages/admin/send/users-list/add-user/add-user.component';
import { UsersListComponent } from './pages/admin/send/users-list/users-list.component';
import { AddWarmupComponent } from './pages/admin/warmup/add-warmup/add-warmup.component';
import { WarmupComponent } from './pages/admin/warmup/warmup.component';
import { ErrorComponent } from './pages/error/error.component';
import { AdminSignInComponent } from './pages/sign-in/admin/admin.component';
import { AuthComponent } from './pages/sign-in/admin/auth/auth.component';
import { RegisterComponent } from './pages/sign-in/admin/register/register.component';
import { IndexComponent } from './pages/sign-in/index/index.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { UserSignInComponent } from './pages/sign-in/user/user.component';
import { ScanComponent } from './pages/template/scan/scan.component';
import { TemplateSendComponent } from './pages/template/send/send.component';
import { TemplateComponent } from './pages/template/template.component';
import { HistoryComponent } from './pages/user/history/history.component';
import { AddPollOptionComponent } from './pages/user/send/content/add-poll-option/add-poll-option.component';
import { ChooseTemplateComponent } from './pages/user/send/content/choose-template/choose-template.component';
import { ContentComponent } from './pages/user/send/content/content.component';
import { RecipientsComponent } from './pages/user/send/recipients/recipients.component';
import { UploadContactsComponent } from './pages/user/send/recipients/upload-contacts/upload-contacts.component';
import { SendComponent } from './pages/user/send/send.component';
import { FeedbackModalComponent } from './pages/user/send/trigger/feedback-modal/feedback-modal.component';
import { TriggerComponent } from './pages/user/send/trigger/trigger.component';
import { UserComponent } from './pages/user/user.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'user', component: UserSignInComponent },
      {
        path: 'admin',
        component: AdminSignInComponent,
        children: [
          { path: '', component: AuthComponent },
          { path: 'register', component: RegisterComponent },
        ],
      },
    ],
  },
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
        path: '',
        redirectTo: 'send',
        pathMatch: 'full',
      },
      {
        path: 'send',
        component: SendComponent,
        children: [
          {
            path: '',
            redirectTo: 'content',
            pathMatch: 'full',
          },
          {
            path: 'content',
            component: ContentComponent,
            children: [
              {
                path: 'add-poll-option',
                component: AddPollOptionComponent,
                outlet: 'modal',
              },
              {
                path: 'choose-template/:id',
                component: ChooseTemplateComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'recipients',
            component: RecipientsComponent,
            children: [
              {
                path: 'upload-contacts',
                component: UploadContactsComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'trigger',
            component: TriggerComponent,
            children: [
              {
                path: 'feedback',
                component: FeedbackModalComponent,
                outlet: 'modal',
              },
            ],
          },
        ],
      },
      {
        path: 'history',
        component: HistoryComponent,
        outlet: 'modal',
      },
    ],
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'send', pathMatch: 'full' },
      { path: 'plans', component: PlansComponent, outlet: 'modal' },
      {
        path: 'send',
        children: [
          { path: '', redirectTo: 'stats', pathMatch: 'full' },
          {
            path: 'stats',
            component: SendStatsComponent,
            children: [
              {
                path: 'campaign/:id',
                component: CampaignComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'users',
            component: UsersListComponent,
            children: [
              {
                path: 'add-user/:id',
                component: AddUserComponent,
                outlet: 'modal',
              },
              {
                path: 'add-user-tag',
                component: AddUserTagComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'templates',
            component: TemplatesListComponent,
            children: [
              {
                path: 'add-template/:id',
                component: AddTemplateComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'plans',
            component: PlansComponent,
            outlet: 'modal',
          },
        ],
      },
      {
        path: 'warmup',
        component: WarmupComponent,
        children: [
          {
            path: 'add/:id',
            component: AddWarmupComponent,
            outlet: 'modal',
          },
          {
            path: 'activate/:id',
            component: ActivateChannelComponent,
            outlet: 'modal',
          },
        ],
      },
      {
        path: 'chat',
        children: [
          { path: '', redirectTo: 'channels', pathMatch: 'full' },
          {
            path: 'flows',
            component: FlowsComponent,
            children: [
              {
                path: 'add/:id',
                component: AddFlowComponent,
                outlet: 'modal',
              },
            ],
          },
          { path: 'flows/:id', component: FlowComponent },
          {
            path: 'categories',
            component: CategoriesComponent,
            children: [
              {
                path: 'add/:id',
                component: AddCategoryComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'channels',
            component: ChannelsComponent,
            children: [
              {
                path: 'add/:id',
                component: AddChannelComponent,
                outlet: 'modal',
              },
              {
                path: 'activate/:id',
                component: ActivateChannelComponent,
                outlet: 'modal',
              },
              {
                path: 'select-flow/:id',
                component: SelectFlowComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'operators',
            component: OperatorsComponent,
            children: [
              {
                path: 'add/:id',
                component: AddOperatorComponent,
                outlet: 'modal',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'template/:id',
    component: TemplateComponent,
    children: [
      {
        path: '',
        component: ScanComponent,
      },
      {
        path: 'send',
        component: TemplateSendComponent,
      },
    ],
  },
  {
    path: 'error/:errorType',
    component: ErrorComponent,
  },
  { path: '**', redirectTo: 'sign-in' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
